import type { TFigure2DViewConfig } from "./types";

export class Figure2DViewConfig {
    readonly aspectRatioWidth: number;
    readonly aspectRatioHeight: number;
    readonly secondaryGridSteps: number;
    readonly initialScale: number;
    readonly maxScale: number;
    readonly primaryGridMultiplier: number;
    readonly initialOffsetX: number;
    readonly initialOffsetY: number;

    constructor(settings: TFigure2DViewConfig) {
        this.initialScale = settings.initialScale;
        this.maxScale = settings.maxScale;
        this.aspectRatioWidth = settings.aspectRatioWidth;
        this.aspectRatioHeight = settings.aspectRatioHeight;
        this.secondaryGridSteps = settings.secondaryGridSteps;
        this.primaryGridMultiplier = settings.primaryGridMultiplier;
        this.initialOffsetX = settings.initialOffsetX;
        this.initialOffsetY = settings.initialOffsetY;
    }

    static create(data: TFigure2DViewConfig) {
        return new Figure2DViewConfig(data);
    }

    static createDefault(overrideDefault?: Partial<TFigure2DViewConfig>) {
        return new Figure2DViewConfig({
            aspectRatioWidth: 1,
            aspectRatioHeight: 1,
            secondaryGridSteps: 40,
            primaryGridMultiplier: 4,
            initialScale: 4,
            maxScale: 40,
            initialOffsetX: 0.5,
            initialOffsetY: 0.5,
            ...overrideDefault,
        });
    }

    clone(viewSettings?: Partial<TFigure2DViewConfig>) {
        return new Figure2DViewConfig({ ...this, ...viewSettings });
    }
}
