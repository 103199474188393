import { computed, makeObservable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getAngles } from "@viuch/geometry-lib/angles";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { TAngle } from "@viuch/geometry-lib/types";
import type { TPoint } from "@viuch/math-editor/types";
import type React from "react";

import { AngleElement } from "../../elements/angle";
import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { PointModel } from "../../models/point";
import { RightAngleModel } from "../../models/right-angle";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class CreateRightAngleFlow extends BaseFlow {
    private mapModel = createTransformer(createMapModelToElements(opacifyColor));
    private readonly center: TPoint;
    private readonly start: TPoint;
    private readonly end: TPoint;

    constructor(data: Figure2DController, angle: TAngle) {
        super(data);
        this.center = angle.vertex;
        this.start = angle.start;
        this.end = angle.end;

        makeObservable(this, {
            tempElements: computed,
        });
    }

    get tempElements(): BaseElement[] {
        const {
            center: { x, y },
            start,
            end,
        } = this;
        const color = ElementColor.Building;
        return [
            new DotElement({
                id: "temp__vertexDot",
                color: ElementColor.Selected,
                x,
                y,
                overrideRenderOrder: 0,
            }),
            new LineElement({
                id: "temp__startLine",
                x1: x,
                y1: y,
                x2: start.x,
                y2: start.y,
                color,
                overrideRenderOrder: 1,
            }),
            new LineElement({
                id: "temp__endLine",
                x1: x,
                y1: y,
                x2: end.x,
                y2: end.y,
                color,
                overrideRenderOrder: 1,
            }),
            this.tempAngleElement,
        ];
    }

    get tempAngleElement() {
        const angle = {
            vertex: this.center,
            start: this.start,
            end: this.end,
        };

        const { startAngle, endAngle } = getAngles(angle);

        return new AngleElement({
            id: `temp__angle`,
            x: angle.vertex.x,
            y: angle.vertex.y,
            angleStart: startAngle,
            angleEnd: endAngle,
            color: ElementColor.Selected,
            overrideRenderOrder: 10,
        });
    }

    attach(): void {
        this.viewport.disable();
    }

    dispose(): void {}

    override handleViewportPointerEvent(e: React.PointerEvent): void {
        if (!e.isPrimary) return;

        switch (e.type) {
            case "pointerdown":
            case "pointermove":
            case "pointerup":
            case "pointercancel":
                break;
            default:
                return;
        }

        if (e.type === "pointerdown") {
            e.preventDefault();
            this.saveChanges();
            this.nextFlow();
        }
    }

    override handleElementPointerEvent(e: React.PointerEvent, element: BaseElement): void {
        if (!e.isPrimary) return;

        switch (e.type) {
            case "pointerdown":
            case "pointermove":
            case "pointerup":
            case "pointercancel":
                break;
            default:
                return;
        }
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "remove":
            case "delete": {
                this.nextFlow();
                break;
            }
            case "labels":
                return this.nextFlow();
            default:
                handleToolbarButtons(this, button);
        }
    }

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModel), ...this.tempElements];
    }

    private saveChanges() {
        const { center: vertex, start, end } = this;

        this.figure.insertModels(function* () {
            yield RightAngleModel.create({ vertex, start, end, style: null });

            yield PointModel.create({ ...vertex, style: null });
            yield PointModel.create({ ...start, style: null });
            yield PointModel.create({ ...end, style: null });
        }, this);
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["labels"])
            .setButtonIcon("label-angle", ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        return new TooltipMenu("Подпишите угол, затем нажмите Enter или в пустую область");
    }
}
