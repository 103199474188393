import { makeObservable, observable } from "mobx";

import { Figure2D } from "@viuch/instrument-figure2d";

import type { Polygon } from "./Polygon";
import type { TFigure2DViewConfig } from "@viuch/instrument-figure2d/entities/types";
import type { TSerializedState } from "@viuch/math-editor";

type TFigure2DConfig = {
    figureEditorInstance: Figure2D;
    systems: TSerializedState[];
    polygons: Polygon[];
};

export class Figure2DSettings {
    @observable.ref figureEditorInstance: Figure2D;
    @observable.shallow polygons: Polygon[];
    @observable.shallow systems: TSerializedState[];

    constructor(data: TFigure2DConfig) {
        this.figureEditorInstance = data.figureEditorInstance;
        this.polygons = data.polygons.slice();
        this.systems = data.systems.slice();

        makeObservable(this);
    }

    static createEmpty(): Figure2DSettings {
        return new Figure2DSettings({
            polygons: [],
            systems: [],
            figureEditorInstance: Figure2D.createEmpty(),
        });
    }

    clone(viewSettings?: Partial<TFigure2DViewConfig>) {
        return new Figure2DSettings({
            polygons: this.polygons.map((polygon) => polygon.clone()),
            systems: this.systems.slice(),
            figureEditorInstance: this.figureEditorInstance.clone(viewSettings),
        });
    }
}
