import { computed, makeObservable, observable } from "mobx";

import { getLineAngle, getMiddleAngle, normalizeAngle } from "@viuch/geometry-lib/angles";
import { isRightAngle } from "@viuch/geometry-lib/check-geometry";
import { tryConvertSimpleToString } from "@viuch/math-editor";
import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TModelStyle } from "../modelStyle";
import type { TPoint } from "@viuch/geometry-lib/types";
import type { TSerializedState } from "@viuch/math-editor";

import { BaseModel } from "../BaseModel";

export interface ILabelAngleModel {
    start: TPoint;
    vertex: TPoint;
    end: TPoint;
    value: TSerializedState;
    preferRightAngle: boolean;
    style: TModelStyle | null;
    is_editable?: boolean;
}

export class LabelAngleModel extends BaseModel implements ILabelAngleModel {
    vertex: TPoint;
    end: TPoint;
    start: TPoint;
    value: TSerializedState;
    preferRightAngle: boolean;
    style: TModelStyle | null;

    constructor(data: ILabelAngleModel, id: number) {
        super(id);
        this.vertex = { ...data.vertex };
        this.end = { ...data.end };
        this.start = { ...data.start };
        this.value = data.value;
        this.preferRightAngle = data.preferRightAngle;
        this.style = data.style;
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            start: observable,
            vertex: observable,
            end: observable,
            isRight: computed,
            value: observable.ref,
            angleStart: computed,
            angleEnd: computed,
            angleMiddle: computed,
            style: observable,
        });
    }

    get isRight(): boolean {
        const { start, vertex, end, value } = this;

        const isRight = isRightAngle({ start, vertex, end });

        if (this.preferRightAngle) {
            return isRight;
        } else if (isRight) {
            const has90Label = !!tryConvertSimpleToString(value)?.startsWith("90");

            return has90Label;
        }

        return false;
    }

    get angleStart(): number {
        const { start, vertex } = this;

        const angle = getLineAngle(
            {
                x1: vertex.x,
                y1: vertex.y,
                x2: start.x,
                y2: start.y,
            },
            true
        );
        return normalizeAngle(angle);
    }

    get angleEnd(): number {
        const { vertex, end } = this;
        return normalizeAngle(
            getLineAngle(
                {
                    x1: vertex.x,
                    y1: vertex.y,
                    x2: end.x,
                    y2: end.y,
                },
                true
            )
        );
    }

    get angleMiddle(): number {
        const { angleStart, angleEnd } = this;

        return getMiddleAngle(angleStart, angleEnd);
    }

    static create(data: ILabelAngleModel) {
        return new LabelAngleModel(data, generateId());
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withLabelAngle(this);
    }
}
