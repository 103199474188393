import type { TAnyControl, TFormControl } from "@viuch/form-builder/types/controls";

import { createLabel } from "../controls/label";
import { answerMathControl, answerTargetFormatMathControl } from "../controls/value";

const geometryFigure: TAnyControl = {
    path: "./settings_uuid",
    control: "Select",
    data: {
        type: "custom-provider",
        selector: "instruments/[type=figure2d]",
    },
    label: "Выберите инструмент",
    initialValue: null,
};

const geometryTheorems: TAnyControl = {
    path: "./theorem_group",
    control: "Select",
    label: "Группа теорем",
    initialValue: "theme",
    data: {
        type: "static-items",
        items: [
            { value: "theme", label: "На основе иерархии тем" },
            { value: "all", label: "Все теоремы" },
            { value: "elementary", label: "1-4 класс" },
        ],
    },
};

function buildConditionalMathForm(path: string, label: string, kind: string | string[]): TFormControl {
    if (!Array.isArray(kind)) {
        kind = [kind];
    }

    return {
        path,
        control: "Form",
        condition: [{ "../kind": kind }],
        layout: "row",
        controls: [
            {
                path: ".",
                control: "Math",
                label,
            },
        ],
    };
}

const quadrangleSelectForm: TFormControl = {
    path: "./quadrangle_kind",
    control: "Form",
    condition: [{ "../kind": ["quadrangle_type"] }],
    layout: "row",
    controls: [
        {
            path: ".",
            control: "Select",
            label: "Тип четырехугольника",
            initialValue: "parallelogram",
            data: {
                type: "static-items",
                items: [
                    { value: "parallelogram", label: "Паралелограм" },
                    { value: "rectangle", label: "Прямоугольник" },
                    { value: "rhombus", label: "Ромб" },
                    { value: "square", label: "Квадрат" },
                ],
            },
        },
    ],
};

const geometryProofTarget: TFormControl = {
    path: "./statement",
    control: "Form",
    layout: "column",
    controls: [
        {
            path: "./kind",
            control: "Select",
            label: "Выберете тип доказательства",
            initialValue: "identity",
            data: {
                type: "static-items",
                items: [
                    { value: "identity", label: "Тождество" },
                    { value: "parallel_lines", label: "Прямые паралельны" },
                    { value: "triangle_equality", label: "Треугольники равны" },
                    { value: "quadrangle_type", label: "Тип четырехугольника" },
                    { value: "triangle_is_isosceles", label: "Треугольник - равнобедренный" },
                    { value: "triangle_is_equilateral", label: "Треугольник - равносторонний" },
                ],
            },
        },
        buildConditionalMathForm("./identity", "Тождество", "identity"),
        buildConditionalMathForm("./one", "Прямая 1", "parallel_lines"),
        buildConditionalMathForm("./other", "Прямая 2", "parallel_lines"),
        buildConditionalMathForm("./one", "Треугольник 1", "triangle_equality"),
        buildConditionalMathForm("./other", "Треугольник 2", "triangle_equality"),
        buildConditionalMathForm("./points", "Четырехугольник", "quadrangle_type"),
        buildConditionalMathForm("./points", "Треугольник", ["triangle_is_isosceles", "triangle_is_equilateral"]),
        quadrangleSelectForm,
    ],
};

export const figureCompletion: TFormControl = {
    control: "Form",
    layout: "column",
    condition: [{ "./solver_type": ["figure_completion"] }],
    controls: [
        createLabel("Данный решатель требует от сделать достроения идентичные достроениям администратора"),
        geometryFigure,
        {
            control: "Checkbox",
            path: "allow_extra_elements",
            label: "Разрешить лишние элементы при проверке",
        },
    ],
};

export const geometry: TFormControl = {
    control: "Form",
    layout: "column",
    condition: [{ "./solver_type": ["geometry", "geometry-dims"] }],
    controls: [
        createLabel("Решает геометрическую задачу по чертежу", { "./solver_type": "geometry" }),
        createLabel("Решает геометрическую задачу по чертежу. Поддерживаются единицы длинны.", {
            "./solver_type": "geometry-dims",
        }),
        answerMathControl,
        geometryFigure,
        geometryTheorems,
        answerTargetFormatMathControl,
    ],
};

export const geometryProve: TFormControl = {
    control: "Form",
    layout: "column",
    condition: [{ "./solver_type": ["geometry_proof"] }],
    controls: [
        createLabel("Доказывает утверждение с использование теорем геометрии", { "./solver_type": "geometry_proof" }),
        geometryFigure,
        geometryTheorems,
        geometryProofTarget,
    ],
};
