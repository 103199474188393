import { getBisectionPoint } from "@viuch/geometry-lib/bisections";
import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { createAngle, createFragment, createLine, fragmentToLine } from "@viuch/geometry-lib/factories";
import { intersectionLineFragment, intersectionLines } from "@viuch/geometry-lib/intersection";

import type { BaseModel, IModelVisitor } from "../models/BaseModel";
import type { TAngle, TFragment, TFragmentWithPoint, TFragmentWithPoints, TPoint } from "@viuch/geometry-lib/types";

export function findBisectionPoints(vertex: TPoint, vertexFragments: TFragmentWithPoints[]): TFragmentWithPoint[] {
    return mapLinePairs<TFragmentWithPoint | null>(
        vertex,
        vertexFragments,
        (left, right): TFragmentWithPoint | null => {
            const point = getBisectionPoint(createAngle(left, vertex, right));
            if (!point) return null;

            return {
                fragment: createFragment(left, right),
                point,
            };
        }
    ).filter<TFragmentWithPoint>(Boolean);
}

function mapLinePairs<T>(
    vertex: TPoint,
    vertexFragments: TFragmentWithPoints[],
    createPair: (left: TPoint, right: TPoint) => T
): T[] {
    const items: T[] = [];
    for (let i = 0; i < vertexFragments.length - 1; i++) {
        for (let j = i + 1; j < vertexFragments.length; j++) {
            const left = vertexFragments[i];
            const right = vertexFragments[j];

            for (const leftPoint of left.points) {
                for (const rightPoint of right.points) {
                    items.push(createPair(leftPoint, rightPoint));
                }
            }
        }
    }

    return items;
}

export type TBisectionBindingData = {
    angle: TAngle;
    basis: TFragment;
    basisOffset?: TPoint;
    angleOffset?: number;
    intersectionPoint: TPoint;
};

export function getBisectionTargetData(
    models: BaseModel[],
    vertex: TPoint,
    point: TPoint,
    fragment: TFragment
): TBisectionBindingData[] {
    const angle = createAngle(fragment.a, vertex, fragment.b);
    const visitor = createGetBisectionTargetsDataVisitor(angle, point);

    return (
        models
            .flatMap<TBisectionBindingData>((model) => model.accept(visitor))
            // исключить биссектрисы с нулевой длинной
            .filter(({ intersectionPoint }) => !areSamePoints(vertex, intersectionPoint))
    );
}

/**
 * Посетитель находит в моделях данные, необходимые для привязки к биссектрисе.
 * Данные возвращаются, если линия биссектрисы пересекает отображаемую область модели.
 */
function createGetBisectionTargetsDataVisitor(angle: TAngle, point: TPoint): IModelVisitor<TBisectionBindingData[]> {
    const _line = createLine(angle.vertex, point);
    const visitor: IModelVisitor<TBisectionBindingData[]> = {
        withFragment: (fragment) => {
            const intersectionPoint = intersectionLineFragment(_line, fragment);
            return intersectionPoint ? [{ basis: fragment, intersectionPoint, angle }] : [];
        },
        withParallelFragment: (fragment) => {
            const intersectionPoint = intersectionLineFragment(_line, fragment);
            return intersectionPoint ? [{ basis: fragment, intersectionPoint, angle }] : [];
        },
        withBisection: ({ bisectionFragment }) => {
            if (!bisectionFragment) return [];
            const intersectionPoint = intersectionLineFragment(_line, bisectionFragment);
            return intersectionPoint ? [{ basis: bisectionFragment, intersectionPoint, angle }] : [];
        },
        withAltitude: ({ altitudeFragment }) => {
            const intersectionPoint = intersectionLineFragment(_line, altitudeFragment);
            return intersectionPoint ? [{ basis: altitudeFragment, intersectionPoint, angle }] : [];
        },
        withConstraintLine: ({ constraintA, constraintB, vertex, virtualFragment, offsetAngle }) => {
            const intersectionPoint = intersectionLineFragment(_line, virtualFragment);
            return intersectionPoint
                ? [
                      {
                          basis: createFragment(constraintA, constraintB),
                          basisOffset: vertex,
                          angleOffset: offsetAngle,
                          intersectionPoint,
                          angle,
                      },
                  ]
                : [];
        },
        withLine: (line) => {
            const intersectionPoint = intersectionLines(_line, createLine(line));
            return intersectionPoint ? [{ basis: line, intersectionPoint, angle }] : [];
        },
        withMedian: ({ medianFragment }) => {
            const intersectionPoint = intersectionLineFragment(_line, medianFragment);
            return intersectionPoint ? [{ basis: medianFragment, intersectionPoint, angle }] : [];
        },
        withTangentLine: (tangent) => {
            if (!tangent.checkIsTangent()) return [];
            const intersectionPoint = intersectionLines(_line, fragmentToLine(tangent.virtualTangentLine));
            return intersectionPoint ? [{ basis: tangent.virtualTangentLine, intersectionPoint, angle }] : [];
        },
        withMiddleLine: ({ middleLineFragment }) => {
            const intersectionPoint = intersectionLineFragment(_line, middleLineFragment);
            return intersectionPoint ? [{ basis: middleLineFragment, intersectionPoint, angle }] : [];
        },
        withTangentLineOnCircle: (tangent) => {
            if (!tangent.hasTangent()) return [];
            const intersectionPoint = intersectionLines(_line, createLine(tangent.virtualTangentFragment));
            return intersectionPoint ? [{ basis: tangent.virtualTangentFragment, intersectionPoint, angle }] : [];
        },
        withPoint: () => [],
        withFragmentDashed: (fragment) => {
            const intersectionPoint = intersectionLineFragment(_line, fragment);
            return intersectionPoint ? [{ basis: fragment, intersectionPoint, angle }] : [];
        },
        withVector: (vector) => {
            const fragment = vector.toFragment();
            const intersectionPoint = intersectionLineFragment(_line, fragment);
            return intersectionPoint ? [{ basis: fragment, intersectionPoint, angle }] : [];
        },
        withVectorDashed: (vector) => {
            const fragment = vector.toFragment();
            const intersectionPoint = intersectionLineFragment(_line, fragment);
            return intersectionPoint ? [{ basis: fragment, intersectionPoint, angle }] : [];
        },
        withLabelPoint: () => [],
        withLabelFragment: () => [],
        withRightAngle: () => [],
        withLabelAngle: () => [],
        withEllipse: () => [],
        withInscribedCircle: () => [],
        withCircumscribedCircle: () => [],
        withEqualAngles: () => [],
        withComputedIntersectionPoints: () => [],
        withEqualSegments: () => [],
    };

    return visitor;
}
