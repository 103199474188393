import type { Figure2D } from "../../entities/Figure2D";
import type { BaseModel, IModelVisitor } from "../models/BaseModel";

export function getNewEqualAnglesSegmentsCount(figure: Figure2D, skipModels: BaseModel[] = []): number {
    const used = new Set<number>();

    const collectSegmentsVisitor: IModelVisitor<void> = {
        withEqualAngles: (equalAngles) => {
            used.add(equalAngles.segmentsCount);
        },
        withBisection: (bisection) => {
            used.add(bisection.segmentsCount);
        },
        withPoint: () => {},
        withFragment: () => {},
        withFragmentDashed: () => {},
        withVector: () => {},
        withVectorDashed: () => {},
        withLabelPoint: () => {},
        withLabelFragment: () => {},
        withRightAngle: () => {},
        withLabelAngle: () => {},
        withLine: () => {},
        withConstraintLine: () => {},
        withComputedIntersectionPoints: () => {},
        withMedian: () => {},
        withAltitude: () => {},
        withEqualSegments: () => {},
        withEllipse: () => {},
        withInscribedCircle: () => {},
        withCircumscribedCircle: () => {},
        withTangentLine: () => {},
        withMiddleLine: () => {},
        withTangentLineOnCircle: () => {},
        withParallelFragment: () => {},
    };

    for (const model of figure.models) {
        const skip = skipModels.includes(model);
        if (!skip) {
            model.accept(collectSegmentsVisitor);
        }
    }

    let i = 1;
    while (used.has(i)) {
        i++;
    }

    return i;
}

export function getNewEqualSegmentsStrokesCount(figure: Figure2D): number {
    const used = new Set<number>();

    const addStrokesVisitor: IModelVisitor<void> = {
        withEqualSegments: (equalSegments) => {
            used.add(equalSegments.segmentsCount);
        },
        withMedian: (median) => {
            used.add(median.segmentsCount);
        },
        withPoint: () => {},
        withFragment: () => {},
        withFragmentDashed: () => {},
        withVector: () => {},
        withVectorDashed: () => {},
        withLabelPoint: () => {},
        withLabelFragment: () => {},
        withRightAngle: () => {},
        withLabelAngle: () => {},
        withLine: () => {},
        withConstraintLine: () => {},
        withComputedIntersectionPoints: () => {},
        withAltitude: () => {},
        withEllipse: () => {},
        withInscribedCircle: () => {},
        withCircumscribedCircle: () => {},
        withTangentLine: () => {},
        withMiddleLine: () => {},
        withTangentLineOnCircle: () => {},
        withParallelFragment: () => {},
        withBisection: () => {},
        withEqualAngles: () => {},
    };

    for (const model of figure.models) {
        model.accept(addStrokesVisitor);
    }

    let i = 1;
    while (used.has(i)) {
        i++;
    }

    return i;
}
