import { computed, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";

import { getAngles, normalizeAngle } from "@viuch/geometry-lib/angles";
import { assert } from "@viuch/utils/debug";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TAngle, TPoint } from "@viuch/geometry-lib/types";
import type React from "react";

import { AngleElement } from "../../elements/angle";
import { DotElement } from "../../elements/dot";
import { ElementColor } from "../../elements/ElementColor";
import { LineElement } from "../../elements/line";
import { PointModel } from "../../models/point";
import { RightAngleModel } from "../../models/right-angle";
import { ButtonStates } from "../../toolbar";
import { TooltipMenu } from "../../toolbar/tooltip";
import { createDefaultToolbarMenuBuilder } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { createMapModelToElements, opacifyColor } from "../utils";

export class CreateRightAngleInteractiveFlow extends BaseFlow {
    flow?:
        | {
              stage: 1;
              drawing: boolean;

              first: TPoint;
          }
        | {
              stage: 2;
              drawing: boolean;

              readonly first: TPoint;
              vertex: TPoint;
          }
        | {
              stage: 3;
              drawing: boolean;

              readonly first: TPoint;
              readonly vertex: TPoint;
              last: TPoint;
          };

    constructor(data: Figure2DController) {
        super(data);

        makeObservable(this, {
            flow: observable,
            tempElements: computed,
        });
    }

    attach(): void {
        this.snap.addViewportGrid().addFigureModelsPoints();
        this.viewport.disable();
    }

    dispose(): void {
        this.snap.clean();
    }

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void {
        if (e.type === "pointercancel") {
            return this.nextFlow();
        }

        const point = this.snap.snap(pointer.canvas, pointer.canvas);

        this.alwaysUpdatePosition(e, point);
        this.startNewDrawings(e, point);
        this.switchNextStages(e);
    }

    private startNewDrawings(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointerdown") {
            if (!this.flow) {
                this.flow = {
                    stage: 1,
                    drawing: true,
                    first: point,
                };
            } else {
                this.flow.drawing = true;
            }
        }
    }

    private alwaysUpdatePosition(e: React.PointerEvent, point: TPoint) {
        if (e.type === "pointermove" || e.type === "pointerdown") {
            if (!this.flow) {
                this.flow = {
                    stage: 1,
                    first: point,
                    drawing: e.type === "pointerdown",
                };
            } else {
                switch (this.flow.stage) {
                    case 1:
                        this.flow.first = point;
                        break;
                    case 2:
                        this.flow.vertex = point;
                        break;
                    case 3:
                        this.flow.last = point;
                        break;
                }
            }
        }
    }

    private switchNextStages(e: React.PointerEvent) {
        if (!!this.flow && e.type === "pointerup") {
            switch (this.flow.stage) {
                case 1:
                    this.flow = {
                        ...this.flow,
                        stage: 2,
                        drawing: false,
                        vertex: this.flow.first,
                    };
                    break;
                case 2:
                    this.flow = {
                        ...this.flow,
                        stage: 3,
                        drawing: false,
                        last: this.flow.first,
                    };
                    break;
                case 3:
                    this.saveChanges();
            }
        }
    }

    private saveChanges() {
        assert(this.flow);
        const { currentAngle } = this;
        console.log(currentAngle);
        if (currentAngle) {
            const { start, vertex, end } = currentAngle;

            this.figure.insertModels(function* () {
                yield RightAngleModel.create({
                    start,
                    vertex,
                    end,
                    style: null,
                });

                yield PointModel.create({ x: start.x, y: start.y, style: null });
                yield PointModel.create({ x: vertex.x, y: vertex.y, style: null });
                yield PointModel.create({ x: end.x, y: end.y, style: null });
            }, this);

            this.nextFlow();
        } else {
            this.nextFlow();
        }
    }

    private mapModels = createTransformer(createMapModelToElements(opacifyColor));

    protected renderElements(): BaseElement[] {
        return [...this.figure.models.flatMap(this.mapModels), ...this.tempElements];
    }

    get currentAngle(): TAngle | null {
        if (this.flow?.stage !== 3) return null;

        const { first: start, vertex, last: end } = this.flow;
        return normalizeAngle({ vertex, start, end }, true);
    }

    get tempElements(): BaseElement[] {
        if (!this.flow) return [];

        const { stage, drawing, first } = this.flow;
        const color = drawing ? ElementColor.Selected : ElementColor.Building;

        switch (stage) {
            case 1: {
                return [
                    DotElement.create({
                        id: "temp__firstDot",
                        color,
                        x: first.x,
                        y: first.y,
                        overrideRenderOrder: 0,
                    }),
                ];
            }
            case 2: {
                const { vertex } = this.flow;
                return [
                    DotElement.create({
                        id: "temp__firstDot",
                        color: ElementColor.Building,
                        x: first.x,
                        y: first.y,
                        overrideRenderOrder: 0,
                    }),
                    DotElement.create({
                        id: "temp__vertexDot",
                        color,
                        x: vertex.x,
                        y: vertex.y,
                        overrideRenderOrder: 0,
                    }),
                    LineElement.create({
                        id: "temp__firstLine",
                        x1: vertex.x,
                        y1: vertex.y,
                        x2: first.x,
                        y2: first.y,
                        color,
                        overrideRenderOrder: 0,
                    }),
                ];
            }
            case 3: {
                const { currentAngle } = this;
                assert(currentAngle);
                const { vertex, last } = this.flow;

                const { startAngle, endAngle } = getAngles(currentAngle);

                const isRight = true;

                return [
                    AngleElement.create({
                        id: "temp__angle",
                        x: vertex.x,
                        y: vertex.y,
                        color: ElementColor.Selected,
                        isRight,
                        angleStart: startAngle,
                        angleEnd: endAngle,
                        overrideRenderOrder: 10,
                        locked: false,
                    }),
                    DotElement.create({
                        id: "temp__firstDot",
                        color: ElementColor.Building,
                        x: first.x,
                        y: first.y,
                        overrideRenderOrder: 0,
                    }),
                    DotElement.create({
                        id: "temp__vertexDot",
                        color: ElementColor.Building,
                        x: vertex.x,
                        y: vertex.y,
                        overrideRenderOrder: 0,
                    }),
                    DotElement.create({
                        id: "temp__lastDot",
                        color,
                        x: last.x,
                        y: last.y,
                        overrideRenderOrder: 0,
                    }),
                    LineElement.create({
                        id: "temp__firstLine",
                        color: ElementColor.Building,
                        x1: vertex.x,
                        y1: vertex.y,
                        x2: first.x,
                        y2: first.y,
                        overrideRenderOrder: 0,
                    }),
                    LineElement.create({
                        id: "temp__lastLine",
                        x1: vertex.x,
                        y1: vertex.y,
                        x2: last.x,
                        y2: last.y,
                        overrideRenderOrder: 0,
                        color,
                    }),
                ];
            }
        }
        return [];
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder()
            .setButtonState(ButtonStates.active, ["labels"])
            .setButtonIcon("right-angle", ["labels"])
            .build();
    }

    override getTooltipMenu(): TooltipMenu | null {
        let text: string;

        switch (this.flow?.stage) {
            case undefined:
            case 1:
                text = `Выберите точку на одной стороне прямого угла`;
                break;
            case 2:
                text = `Выберите вершину прямого угла`;
                break;
            case 3:
                text = `Выберите точку на другой стороне прямого угла`;
                break;
        }

        return new TooltipMenu(text);
    }
}
