import type { CircumscribedCircleModel, EllipseModel, InscribedCircleModel } from "./circle";
import type { ComputedIntersectionPoints } from "./computed";
import type { EqualAnglesModel, EqualSegmentsModel } from "./constraints";
import type {
    ParallelFragmentModel,
    FragmentModel,
    LineModel,
    ConstraintLineModel,
    FragmentDashedModel,
    VectorModel,
    VectorDashedModel,
} from "./fragment";
import type { AltitudeModel, BisectionModel, MedianModel } from "./geometry";
import type { MiddleLineModel } from "./geometry/MiddleLineModel";
import type { LabelAngleModel } from "./label-angle";
import type { LabelFragmentModel } from "./label-fragment";
import type { LabelPointModel } from "./label-point";
import type { PointModel } from "./point";
import type { RightAngleModel } from "./right-angle";
import type { TangentLineToCircleModel, TangentLineOnCircleModel } from "./tangent";

export abstract class BaseModel {
    readonly id: number;
    readonly timestamp: number;
    is_editable?: boolean;

    protected constructor(id: number) {
        this.id = id;
        this.timestamp = +new Date();
        this.is_editable = true;
    }

    abstract accept<R>(visitor: IModelVisitor<R>): R;

    update(): void {}
}

export interface IModelVisitor<R> {
    withPoint: (point: PointModel) => R;
    withFragment: (fragment: FragmentModel) => R;
    withFragmentDashed: (fragment: FragmentDashedModel) => R;
    withVector: (vector: VectorModel) => R;
    withVectorDashed: (vector: VectorDashedModel) => R;
    withLabelPoint: (label: LabelPointModel) => R;
    withLabelFragment: (label: LabelFragmentModel) => R;
    withLabelAngle: (label: LabelAngleModel) => R;
    withRightAngle: (rightAngle: RightAngleModel) => R;
    withLine: (line: LineModel) => R;
    withConstraintLine: (line: ConstraintLineModel) => R;
    withComputedIntersectionPoints: (model: ComputedIntersectionPoints) => R;
    withBisection: (bisection: BisectionModel) => R;
    withMedian: (median: MedianModel) => R;
    withAltitude: (altitude: AltitudeModel) => R;
    withEqualAngles: (equalAngles: EqualAnglesModel) => R;
    withEqualSegments: (equalSegments: EqualSegmentsModel) => R;
    withEllipse: (ellipse: EllipseModel) => R;
    withInscribedCircle: (circle: InscribedCircleModel) => R;
    withCircumscribedCircle: (circle: CircumscribedCircleModel) => R;
    withTangentLine: (tangent: TangentLineToCircleModel) => R;
    withMiddleLine: (middleLine: MiddleLineModel) => R;
    withTangentLineOnCircle: (tangent: TangentLineOnCircleModel) => R;
    withParallelFragment: (fragment: ParallelFragmentModel) => R;
}
