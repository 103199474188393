import { computed, makeObservable, observable } from "mobx";

import { getUnitVectorFromAngle } from "@viuch/geometry-lib/angles";
import { areSamePoints } from "@viuch/geometry-lib/check-geometry";
import { copyPoint, createLine } from "@viuch/geometry-lib/factories";
import { intersectionLines } from "@viuch/geometry-lib/intersection";
import { getFragmentAngle, rotatePoint } from "@viuch/geometry-lib/solvers";
import { addVectors, middlePoint, subtractVectors, vectorLength } from "@viuch/geometry-lib/vectors";
import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TModelStyle } from "../modelStyle";
import type { TEllipse, TFragment, TLine, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface ICircumscribedCircleModel {
    points: TPoint[];
    style: TModelStyle | null;
    is_editable?: boolean;
}

export class CircumscribedCircleModel extends BaseModel {
    points: TPoint[];
    style: TModelStyle | null;

    constructor(data: ICircumscribedCircleModel, id: number) {
        super(id);

        this.points = data.points.map(copyPoint);
        this.style = data.style;
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            points: observable,
            fragments: computed,
            center: computed,
            radius: computed,
            style: observable,
        });
    }

    static create(data: ICircumscribedCircleModel) {
        return new CircumscribedCircleModel(data, generateId());
    }

    get fragments(): TFragment[] {
        const fragments: TFragment[] = [];
        for (let i = 0; i < this.points.length - 1; i++) {
            const a = this.points[i];
            const b = this.points[i + 1];
            fragments.push({ a, b });
        }
        fragments.push({ a: this.points.at(-1)!, b: this.points[0] });
        return fragments;
    }

    private getCentralPerpendiculars(fragments: TFragment[]): TLine[] {
        return fragments.map<TLine>((fragment) => {
            const midPoint = middlePoint(fragment);
            const angle = getFragmentAngle(fragment, true);
            const directVector = getUnitVectorFromAngle(angle);
            const perpendicularVector = rotatePoint(directVector, Math.PI / 2);
            return createLine(midPoint, addVectors(perpendicularVector, midPoint));
        });
    }

    private getCenter(cps: TLine[]): TPoint | null {
        const basePoint = intersectionLines(cps[0], cps.at(-1)!);
        if (!basePoint) return null;

        for (let i = 0; i < cps.length - 1; i++) {
            const point = intersectionLines(cps[i], cps[i + 1]);
            if (!point || !areSamePoints(basePoint, point)) {
                return null;
            }
        }

        return basePoint;
    }

    get center(): TPoint | null {
        const cps = this.getCentralPerpendiculars(this.fragments);
        return this.getCenter(cps);
    }

    get radius(): number | null {
        if (!this.center) return null;

        const radiusVector = subtractVectors(this.center, this.points[0]);
        return vectorLength(radiusVector);
    }

    get rx() {
        return this.radius;
    }

    get ry() {
        return this.radius;
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withCircumscribedCircle(this);
    }

    isEllipse(): this is TEllipse & { radius: number } {
        return !!this.center;
    }
}
