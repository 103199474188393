import type { BaseModel } from "../statement-editor/models";
import type { IModelVisitor } from "../statement-editor/models/BaseModel";

import { CircumscribedCircleModel, EllipseModel, InscribedCircleModel } from "../statement-editor/models/circle";
import { ComputedIntersectionPoints } from "../statement-editor/models/computed";
import { EqualAnglesModel, EqualSegmentsModel } from "../statement-editor/models/constraints";
import {
    ConstraintLineModel,
    FragmentDashedModel,
    FragmentModel,
    LineModel,
    ParallelFragmentModel,
    VectorDashedModel,
    VectorModel,
} from "../statement-editor/models/fragment";
import { AltitudeModel, BisectionModel, MedianModel } from "../statement-editor/models/geometry";
import { MiddleLineModel } from "../statement-editor/models/geometry/MiddleLineModel";
import { LabelAngleModel } from "../statement-editor/models/label-angle";
import { LabelFragmentModel } from "../statement-editor/models/label-fragment";
import { LabelPointModel } from "../statement-editor/models/label-point";
import { PointModel } from "../statement-editor/models/point";
import { RightAngleModel } from "../statement-editor/models/right-angle";
import { TangentLineToCircleModel, TangentLineOnCircleModel } from "../statement-editor/models/tangent";

const cloneFigureModelVisitor: IModelVisitor<BaseModel> = {
    withPoint: (point) => PointModel.create(point),
    withFragment: (fragment) => FragmentModel.create(fragment),
    withLine: (line) => LineModel.create(line),
    withAltitude: (altitude) => AltitudeModel.create(altitude),
    withMedian: (median) => MedianModel.create(median),
    withBisection: (bisection) => BisectionModel.create(bisection),
    withEllipse: (ellipse) => EllipseModel.create(ellipse),
    withInscribedCircle: (circle) => InscribedCircleModel.create(circle),
    withCircumscribedCircle: (circle) => CircumscribedCircleModel.create(circle),
    withConstraintLine: (line) => ConstraintLineModel.create(line),
    withLabelPoint: (label) => LabelPointModel.create(label),
    withLabelFragment: (label) => LabelFragmentModel.create(label),
    withRightAngle: (angle) => RightAngleModel.create(angle),
    withLabelAngle: (label) => LabelAngleModel.create(label),
    withEqualAngles: (equalAngles) => EqualAnglesModel.create(equalAngles),
    withEqualSegments: (equalSegments) => EqualSegmentsModel.create(equalSegments),
    withComputedIntersectionPoints: () => ComputedIntersectionPoints.create(),
    withTangentLine: (tangent) => TangentLineToCircleModel.create(tangent),
    withMiddleLine: (middleLine) => MiddleLineModel.create(middleLine),
    withTangentLineOnCircle: (tangent) => TangentLineOnCircleModel.create(tangent),
    withParallelFragment: (fragment) => ParallelFragmentModel.create(fragment),
    withVector: (vector) => VectorModel.create(vector),
    withVectorDashed: (vector) => VectorDashedModel.create(vector),
    withFragmentDashed: (fragment) => FragmentDashedModel.create(fragment),
};

export function cloneFigureModel(model: BaseModel): BaseModel {
    return model.accept(cloneFigureModelVisitor);
}
