import { makeObservable, observable } from "mobx";

import type { TFigure2DInstrumentStatement, TFigure2DViewConfig } from "./types";
import type { IHistorySnapshot } from "../statement-editor/history";
import type { TSnapshotModel } from "../statement-editor/history/types";
import type { BaseModel } from "../statement-editor/models";

import { HistorySnapshot } from "../statement-editor/history";
import { createModelSnapshot, mapSnapshotToModel } from "../statement-editor/history/utils";

import { Figure2DViewConfig } from "./Figure2DViewConfig";
import { cloneFigureModel } from "./utils";

export class Figure2D {
    readonly settings: Figure2DViewConfig;
    @observable.shallow models: BaseModel[];

    constructor(data: TFigure2DInstrumentStatement) {
        this.settings = Figure2DViewConfig.create(data.settings);
        this.models = data.models;

        makeObservable(this);
    }

    static createEmpty() {
        return new Figure2D({
            settings: Figure2DViewConfig.createDefault(),
            models: [],
        });
    }

    set(models: BaseModel[]) {
        this.models = models;
    }

    clear() {
        this.models = [];
    }

    clone(viewSettings?: Partial<TFigure2DViewConfig>) {
        return new Figure2D({
            models: this.models.map(cloneFigureModel),
            settings: this.settings.clone(viewSettings),
        });
    }

    static createFromSnapshot(snapshot: IHistorySnapshot, overrideSettings?: Partial<TFigure2DViewConfig>) {
        return new Figure2D({
            settings: Figure2DViewConfig.createDefault(overrideSettings),
            models: snapshot.models.map(mapSnapshotToModel),
        });
    }

    makeSnapshot(): HistorySnapshot {
        const models = this.models.map(createModelSnapshot).filter<TSnapshotModel>(Boolean);
        return new HistorySnapshot(models);
    }

    removeModel(model: BaseModel): boolean {
        return this.models.remove(model);
    }

    addModel(model: BaseModel): void {
        this.models.push(model);
    }

    filterModels(filterFn: (model: BaseModel, i: number) => boolean) {
        this.models = this.models.filter(filterFn);
    }

    insertModels(generator: () => Iterable<BaseModel>): void;
    insertModels<This>(generator: (this: This) => Iterable<BaseModel>, thisArg: This): void;
    insertModels(generator: { (): Iterable<BaseModel> }, thisArg?: unknown) {
        const values = thisArg ? generator.call(thisArg) : generator();
        for (const baseModel of values) {
            this.models.push(baseModel);
        }
    }
}
