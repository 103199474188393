import { computed, makeObservable, observable } from "mobx";

import { getLineAngle, getMiddleAngle, normalizeAngle } from "@viuch/geometry-lib/angles";
import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TModelStyle } from "../modelStyle";
import type { TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IRightAngleModel {
    start: TPoint;
    vertex: TPoint;
    end: TPoint;
    style: TModelStyle | null;
    is_editable?: boolean;
}

export class RightAngleModel extends BaseModel implements IRightAngleModel {
    vertex: TPoint;
    end: TPoint;
    start: TPoint;
    style: TModelStyle | null;

    constructor(data: IRightAngleModel, id: number) {
        super(id);
        this.vertex = { ...data.vertex };
        this.end = { ...data.end };
        this.start = { ...data.start };
        this.style = data.style;
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            start: observable,
            vertex: observable,
            end: observable,
            angleStart: computed,
            angleEnd: computed,
            angleMiddle: computed,
            style: observable,
        });
    }

    get angleStart(): number {
        const { start, vertex } = this;

        const angle = getLineAngle(
            {
                x1: vertex.x,
                y1: vertex.y,
                x2: start.x,
                y2: start.y,
            },
            true
        );
        return normalizeAngle(angle);
    }

    get angleEnd(): number {
        const { vertex, end } = this;
        return normalizeAngle(
            getLineAngle(
                {
                    x1: vertex.x,
                    y1: vertex.y,
                    x2: end.x,
                    y2: end.y,
                },
                true
            )
        );
    }

    get angleMiddle(): number {
        const { angleStart, angleEnd } = this;

        return getMiddleAngle(angleStart, angleEnd);
    }

    static create(data: IRightAngleModel) {
        return new RightAngleModel(data, generateId());
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withRightAngle(this);
    }
}
