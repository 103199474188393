import { computed, makeObservable, observable } from "mobx";

import { getLineAngle, getUnitVectorFromAngle, normalizeAngle } from "@viuch/geometry-lib/angles";
import { copyPoint, createLine } from "@viuch/geometry-lib/factories";
import { intersectionLines } from "@viuch/geometry-lib/intersection";
import { getLineFromFragment, rotatePoint } from "@viuch/geometry-lib/solvers";
import { addVectors } from "@viuch/geometry-lib/vectors";
import { generateId } from "@viuch/shared/utils/data";

import type { TFragment, TLine, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IConstraintLineModel {
    constraintA: TPoint;
    constraintB: TPoint;
    offsetAngle: number;
    vertex: TPoint;
    is_editable?: boolean;
}

export class ConstraintLineModel extends BaseModel implements IConstraintLineModel {
    constraintA: TPoint;
    constraintB: TPoint;
    vertex: TPoint;
    offsetAngle: number;

    constructor(data: IConstraintLineModel, id: number) {
        super(id);
        this.constraintA = copyPoint(data.constraintA);
        this.constraintB = copyPoint(data.constraintB);
        this.vertex = copyPoint(data.vertex);
        this.offsetAngle = data.offsetAngle;
        this.is_editable = data.is_editable ?? true;

        makeObservable<this, "angleReversed">(this, {
            constraintA: observable,
            constraintB: observable,
            vertex: observable,
            offsetAngle: observable,
            virtualFragment: computed,
            constraintAngle: computed,
            lineAngle: computed,
            angleReversed: computed,
        });
    }

    private get angleReversed(): boolean {
        const { constraintA, constraintB, vertex } = this;
        const angle = getLineAngle(createLine(constraintA, constraintB), true);

        const _b = rotatePoint(constraintB, angle, vertex, true).x;
        const _x = vertex.x;

        return _x < _b;
    }

    get constraintAngle(): number {
        const { constraintA, constraintB } = this;
        const angle = getLineAngle(createLine(constraintA, constraintB), true);

        return this.angleReversed ? angle : normalizeAngle(angle - Math.PI);
    }

    get lineAngle(): number {
        return normalizeAngle(this.constraintAngle + Math.PI / 2);
    }

    get virtualFragment(): TFragment {
        const { vertex, constraintA, constraintB, offsetAngle } = this;

        const lineAngle = getLineAngle(createLine(constraintA, constraintB));
        const vector = getUnitVectorFromAngle(offsetAngle - lineAngle);

        return getLineFromFragment(vertex, addVectors(vertex, vector));
    }

    static create(data: IConstraintLineModel) {
        return new ConstraintLineModel(data, generateId());
    }

    accept<R>(visitor: IConstraintLineModelVisitor<R>): R {
        return visitor.withConstraintLine(this);
    }

    toVirtualLine(): TLine {
        const { a, b } = this.virtualFragment;
        return createLine(a, b);
    }

    get intersectionPoint(): TPoint | null {
        const basis = createLine(this.constraintA, this.constraintB);
        return intersectionLines(basis, this.toVirtualLine());
    }

    tryGetIntersectionPoint(): TPoint | null {
        const basis = createLine(this.constraintA, this.constraintB);
        return intersectionLines(basis, this.toVirtualLine());
    }
}

export interface IConstraintLineModelVisitor<R> {
    withConstraintLine: (line: ConstraintLineModel) => R;
}
