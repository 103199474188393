import { action, makeObservable, observable } from "mobx";

import { createSerializedContainer } from "@viuch/math-editor";

import type { BaseVariableContent } from "./variables";
import type { TSerializedState } from "@viuch/math-editor";

import { VariableGenerationTypes } from "./constants";
import { PrimaryHints } from "./PrimaryHints";
import { Variable } from "./Variable";
import { SolverVariableContent } from "./variables";

export class Answer<T extends BaseVariableContent = BaseVariableContent> extends Variable<T> {
    @observable.ref description: string;
    @observable.ref descriptionFormula: TSerializedState;
    @observable.ref isVisible: boolean;
    @observable primaryHints: PrimaryHints | null;

    constructor(
        description: string,
        description_formula: TSerializedState | null,
        is_visible: boolean,
        name: string,
        generation: VariableGenerationTypes,
        primaryHints: PrimaryHints | null,
        content: T
    ) {
        super(name, generation, content);
        this.description = description;
        this.descriptionFormula = description_formula || createSerializedContainer();
        this.isVisible = is_visible;
        this.primaryHints = primaryHints;

        makeObservable(this);
    }

    @action.bound
    enablePrimaryHints() {
        this.primaryHints ??= new PrimaryHints([]);
    }

    @action.bound
    removePrimaryHints() {
        this.primaryHints = null;
    }

    @action.bound
    toggleVisible() {
        this.isVisible = !this.isVisible;
    }

    static override createEmpty(): Answer {
        return new Answer(
            "",
            createSerializedContainer(),
            true,
            "",
            VariableGenerationTypes.solver,
            null,
            SolverVariableContent.createEmpty()
        );
    }

    override clone(): Answer {
        return new Answer(
            this.description,
            this.descriptionFormula,
            this.isVisible,
            this.name,
            this.generation,
            this.primaryHints?.clone() ?? null,
            this.content.clone()
        );
    }
}
