import { getClassOf } from "@viuch/shared/utils/runtime/classOf";

import type { InferConstructor, TSerializedState } from "../types";

export const generateCharsArray = (charA: string, charZ: string) => {
    const alphabet = [];
    let i = charA.charCodeAt(0);
    const j = charZ.charCodeAt(0);

    for (; i <= j; ++i) {
        alphabet.push(String.fromCharCode(i));
    }

    return alphabet;
};

export function getClassFromInstance<T extends InferConstructor<I>, I>(instance: I): T {
    return getClassOf(instance);
}

export function lastInArray<T>(array: T[]): T | undefined {
    return array[array.length - 1];
}

export function compare<T>(left: T, right: T): number {
    return left > right ? 1 : left < right ? -1 : 0;
}

export function isSerializedState(data: TSerializedState & unknown): data is TSerializedState {
    return typeof data === "object" && typeof data.elements === "object" && Array.isArray(data.elements);
}

export function isZeroLengthMathExpr(state: TSerializedState): boolean {
    return state?.elements?.length === 0;
}
