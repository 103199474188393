import { computed, makeObservable, observable } from "mobx";

import { createLine } from "@viuch/geometry-lib/factories";
import { getLineFromFragment } from "@viuch/geometry-lib/solvers";
import { generateId } from "@viuch/shared/utils/data";

import type { TModelStyle } from "../modelStyle";
import type { TFragment, TLine, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface ILineModel {
    a: TPoint;
    b: TPoint;
    style: TModelStyle | null;
    is_editable?: boolean;
}

export interface ILineModelVisitor<R> {
    withLine: (line: LineModel) => R;
}

export class LineModel extends BaseModel implements ILineModel {
    a: TPoint;
    b: TPoint;
    style: TModelStyle | null;

    constructor(data: ILineModel, id: number) {
        super(id);

        this.a = { ...data.a };
        this.b = { ...data.b };
        this.style = data.style;
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            a: observable,
            b: observable,
            virtualFragment: computed,
            style: observable,
        });
    }

    get virtualFragment(): TFragment {
        const { a, b } = this;
        return getLineFromFragment(a, b);
    }

    accept<R>(visitor: ILineModelVisitor<R>): R {
        return visitor.withLine(this);
    }

    static create(data: ILineModel) {
        return new LineModel(data, generateId());
    }

    toLine(): TLine {
        return createLine(this.a, this.b);
    }

    toVirtualLine(): TLine {
        const { a, b } = this.virtualFragment;
        return createLine(a, b);
    }
}
