import { comparer, reaction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

import { InputService } from "@viuch/math-editor";
import { useConst, usePropsRef } from "@viuch/utils/hooks";

import type { KeyboardService, TSerializedState } from "@viuch/math-editor";
import type { FC } from "react";

import { MathInput } from "./MathInput";

type Props = {
    value: TSerializedState;
    onChange(value: TSerializedState): void;
    keyboardService?: KeyboardService;
    triggerChangeEachInput?: boolean;
    title?: string;
    error?: string;
    inline?: boolean;
    className?: string;
    disabled?: boolean;
    multiline?: boolean;
    withoutScrollbar?: boolean;
};

export const MathInput2: FC<Props> = observer(function MathInput2(props) {
    const propsRef = usePropsRef(props);
    const {
        value,
        className,
        title,
        onChange,
        keyboardService,
        error,
        inline,
        multiline,
        triggerChangeEachInput,
        disabled,
        withoutScrollbar,
    } = props;

    const inputService = useConst(() => new InputService({ initialState: value, multiline }));

    useEffect(() => {
        inputService.setSerializedState(value);
    }, [inputService, value]);

    const handleChanged = () => {
        onChange(inputService.getSerializedState());
    };

    useEffect(() => {
        if (!triggerChangeEachInput) return;

        const d = reaction(
            () => inputService.getSerializedState(),
            (state) => {
                propsRef.current.onChange(state);
            },
            { equals: comparer.default }
        );

        return () => d();
    }, [inputService, propsRef, triggerChangeEachInput]);

    return (
        <MathInput
            inputService={inputService}
            onBlur={triggerChangeEachInput ? void 0 : handleChanged}
            keyboardService={keyboardService}
            error={error}
            title={title}
            inline={inline}
            className={className}
            disabled={disabled}
            withoutScrollbar={withoutScrollbar}
        />
    );
});
