import { action, makeObservable } from "mobx";
import { createTransformer } from "mobx-utils";

import { areSameFragments } from "@viuch/geometry-lib/check-geometry";
import { copyFragment, createFragment } from "@viuch/geometry-lib/factories";

import type { BaseElement } from "../../elements";
import type { Figure2DController } from "../../Figure2DController";
import type { IModelVisitor } from "../../models/BaseModel";
import type { LabelAngleModel } from "../../models/label-angle";
import type { RightAngleModel } from "../../models/right-angle";
import type { ToolbarButton, ToolbarMenu } from "../../toolbar";
import type { IUserPointer } from "../../viewport/types";
import type { TFragment } from "@viuch/geometry-lib/types";
import type React from "react";

import { checkElementOwnByModel } from "../../elements/utils";
import { checkModelType, ModelTypes } from "../../services/actions/utils";
import { ButtonStates } from "../../toolbar";
import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../../utils/toolbar";
import { BaseFlow } from "../BaseFlow";
import { CreateLabelAngleFlow } from "../create-label-angle";
import { CreateLabelDotFlow } from "../create-label-dot";
import { LabelFragmentFlow } from "../create-label-fragment";
import { CreateRightAngleFlow } from "../create-right-angle/CreateRightAngleFlow";
import { MoveDotFlow } from "../move-dot";
import { MoveFragmentFlow } from "../move-fragment";
import { SelectElementsFlow } from "../select-elements";
import { createMapModelToElements } from "../utils";

export class DefaultFlow extends BaseFlow {
    constructor(data: Figure2DController) {
        super(data);

        makeObservable(this, {
            attach: action.bound,
            resetModels: action.bound,
        });
    }

    override getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder().setButtonState(ButtonStates.active, ["cursor"]).build();
    }

    static create(data: Figure2DController) {
        return new DefaultFlow(data);
    }

    mapModel = createTransformer(createMapModelToElements());

    renderElements(): BaseElement[] {
        return this.figure.models.flatMap(this.mapModel);
    }

    attach = () => {
        this.viewport.enable();
    };

    dispose() {}

    override handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer) {}

    override handleElementPointerEvent(e: React.PointerEvent, element: BaseElement, pointer: IUserPointer) {
        switch (e.type) {
            case "pointerdown": {
                element.accept<void>({
                    withDot: (dot) => {
                        e.preventDefault();
                        e.currentTarget.setPointerCapture(e.pointerId);
                        this.nextFlow((data) => new MoveDotFlow(data, dot));
                    },
                    withLine: (line) => {
                        e.currentTarget.setPointerCapture(e.pointerId);

                        const selected = line.toFragment();

                        const modelHasFragmentVisitor: IModelVisitor<boolean> = {
                            withLine: ({ virtualFragment }) => areSameFragments(virtualFragment, selected),
                            withFragment: (fragment) => areSameFragments(fragment, selected),
                            withFragmentDashed: (fragment) => areSameFragments(fragment, selected),
                            withVector: (vector) => areSameFragments(vector.toFragment(), selected),
                            withVectorDashed: (vector) => areSameFragments(vector.toFragment(), selected),
                            withConstraintLine: ({ virtualFragment }) => areSameFragments(virtualFragment, selected),
                            withBisection: ({ bisectionFragment }) =>
                                bisectionFragment ? areSameFragments(bisectionFragment, selected) : false,
                            withMedian: ({ medianFragment }) => areSameFragments(medianFragment, selected),
                            withAltitude: ({ altitudeFragment }) => areSameFragments(altitudeFragment, selected),
                            withTangentLine: ({ virtualTangentLine }) =>
                                virtualTangentLine ? areSameFragments(virtualTangentLine, selected) : false,
                            withMiddleLine: ({ middleLineFragment }) => areSameFragments(middleLineFragment, selected),
                            withTangentLineOnCircle: (tangent) =>
                                tangent.hasTangent()
                                    ? areSameFragments(tangent.virtualTangentFragment, selected)
                                    : false,
                            withParallelFragment: (fragment) => areSameFragments(fragment, selected),
                            withPoint: () => false,
                            withLabelPoint: () => false,
                            withLabelFragment: () => false,
                            withRightAngle: () => false,
                            withLabelAngle: () => false,
                            withComputedIntersectionPoints: () => false,
                            withEqualAngles: () => false,
                            withEqualSegments: () => false,
                            withEllipse: () => false,
                            withInscribedCircle: () => false,
                            withCircumscribedCircle: () => false,
                        };

                        const getFragmentPointsToMove: IModelVisitor<TFragment | null> = {
                            withLine: copyFragment,
                            withFragment: copyFragment,
                            withFragmentDashed: copyFragment,
                            withVector: (vector) => vector.toFragment(),
                            withVectorDashed: (vector) => vector.toFragment(),
                            withParallelFragment: copyFragment,
                            withConstraintLine: ({ vertex }) => createFragment(vertex, vertex),
                            withBisection: ({ bisectionFragment }) =>
                                bisectionFragment ? copyFragment(bisectionFragment) : null,
                            withMedian: ({ medianFragment }) => copyFragment(medianFragment),
                            withAltitude: ({ altitudeFragment }) => copyFragment(altitudeFragment),
                            withTangentLine: (tangent) =>
                                tangent.checkIsTangent() ? createFragment(tangent.point, tangent.pointOnCircle) : null,
                            withMiddleLine: ({ middleLineFragment }) => copyFragment(middleLineFragment),
                            withTangentLineOnCircle: ({ touchPoint }) => createFragment(touchPoint, touchPoint),
                            withPoint: () => null,
                            withLabelPoint: () => null,
                            withLabelFragment: () => null,
                            withRightAngle: () => null,
                            withLabelAngle: () => null,
                            withComputedIntersectionPoints: () => null,
                            withEqualAngles: () => null,
                            withEqualSegments: () => null,
                            withEllipse: () => null,
                            withInscribedCircle: () => null,
                            withCircumscribedCircle: () => null,
                        };

                        const fragment = this.figure.models
                            .find((model) => model.accept<boolean>(modelHasFragmentVisitor))
                            ?.accept<TFragment | null>(getFragmentPointsToMove);

                        if (fragment) {
                            this.nextFlow((data) => new MoveFragmentFlow(data, fragment, selected, pointer.canvas));
                        }
                    },
                    withVector: (vector) => {
                        const fragment = vector.toFragment();
                        this.nextFlow((data) => new MoveFragmentFlow(data, fragment, fragment, pointer.canvas));
                    },
                    withLabelDot: (label) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const { x, y } = label;
                        this.nextFlow((data) => new CreateLabelDotFlow(data, { x, y }));
                    },
                    withAngle: (angle) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const model = this.figure.models.find(
                            (model): model is RightAngleModel =>
                                checkModelType(model, ModelTypes.rightAngle) && checkElementOwnByModel(model, angle)
                        );
                        // const model = this.figure.models.find(
                        //     (model): model is LabelAngleModel =>
                        //         checkModelType(model, ModelTypes.labelAngle) && checkElementOwnByModel(model, angle)
                        // );
                        if (!model) return;
                        // const { vertex, start, end, preferRightAngle } = model;
                        // this.nextFlow(
                        //     (data) => new CreateLabelAngleFlow(data, { vertex, start, end }, preferRightAngle)
                        // );
                        const { vertex, start, end } = model;
                        this.nextFlow((data) => new CreateRightAngleFlow(data, { vertex, start, end }));
                    },
                    withLabelAngle: (label) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const model = this.figure.models.find(
                            (model): model is LabelAngleModel =>
                                checkModelType(model, ModelTypes.labelAngle) && checkElementOwnByModel(model, label)
                        );
                        if (!model) return;
                        const { vertex, start, end, preferRightAngle } = model;
                        this.nextFlow(
                            (data) => new CreateLabelAngleFlow(data, { vertex, start, end }, preferRightAngle)
                        );
                    },
                    withLabelFragment: (label) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const { a, b } = label;
                        this.nextFlow((data) => new LabelFragmentFlow(data, { a, b }));
                    },
                    withStroke: () => {},
                    withEllipse: (ellipse) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.selection.toggleEllipse(ellipse);
                        this.nextFlow(SelectElementsFlow.create);
                    },
                });
                break;
            }
        }
    }

    override handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        switch (button.key) {
            case "cursor":
                break;
            case "undo":
                this.history.undo();
                this.nextFlow();
                break;
            case "redo":
                this.history.redo();
                this.nextFlow();
                break;
            case "clear":
                this.controller.handlers.onResetAsync?.().then((shouldDelete) => {
                    if (shouldDelete) {
                        this.resetModels();
                    }
                });
                break;
            default:
                handleToolbarButtons(this, button);
        }
    }

    override handleKeyEvent(e: React.KeyboardEvent) {
        switch (e.key.toLowerCase()) {
            case "z":
            case "я":
                if (e.ctrlKey) {
                    e.preventDefault();
                    if (!e.shiftKey) {
                        this.history.undo();
                        this.nextFlow();
                    } else {
                        this.history.redo();
                        this.nextFlow();
                    }
                }
                break;
            case "y":
            case "н":
                if (e.ctrlKey) {
                    e.preventDefault();
                    this.history.redo();
                    this.nextFlow();
                }
                break;
            case "delete":
            case "backspace":
                e.preventDefault();
                this.controller.handlers.onResetAsync?.().then((shouldDelete) => {
                    if (shouldDelete) {
                        this.resetModels();
                    }
                });
                break;
        }
    }

    resetModels = () => {
        this.figure.set(this.figure.models.filter((model) => !model.is_editable));
        this.history.commit();
        this.nextFlow();
    };
}
