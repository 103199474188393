import { computed, makeObservable, observable } from "mobx";

import { getUnitVectorFromAngle } from "@viuch/geometry-lib/angles";
import { copyFragment, copyPoint } from "@viuch/geometry-lib/factories";
import { getFragmentAngle } from "@viuch/geometry-lib/solvers";
import { addVectors, multiplyVector, subtractVectors, vectorLength } from "@viuch/geometry-lib/vectors";
import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TFragment, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IParallelFragment {
    a: TPoint;
    b: TPoint;
    base: TFragment;
    is_editable?: boolean;
}

export class ParallelFragmentModel extends BaseModel implements IParallelFragment {
    a: TPoint;
    b: TPoint;
    base: TFragment;

    constructor(data: IParallelFragment, id: number) {
        super(id);

        this.base = copyFragment(data.base);
        this.a = copyPoint(data.a);
        this.b = copyPoint(data.b);
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            a: observable,
            b: observable,
            base: observable,
            fragmentLength: computed,
            angle: computed,
        });
    }

    get fragmentLength(): number {
        return vectorLength(subtractVectors(this.a, this.b));
    }

    get angle(): number {
        return getFragmentAngle(this.base, true);
    }

    override update() {
        const unitVector = getUnitVectorFromAngle(this.angle);
        const vector = multiplyVector(unitVector, this.fragmentLength);
        this.b = addVectors(this.a, vector);
    }

    static create(data: IParallelFragment) {
        return new ParallelFragmentModel(data, generateId());
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withParallelFragment(this);
    }
}
