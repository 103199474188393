import { computed, makeObservable, observable } from "mobx";

import { copyEllipse, copyPoint } from "@viuch/geometry-lib/factories";
import { getLineFromFragment } from "@viuch/geometry-lib/solvers";
import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TEllipse, TFragment, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

import { getTangentPoints } from "./tangent-utils";

export type TTangentLine = {
    point: TPoint;
    circle: TEllipse;
    forwardDirection: boolean;
    is_editable?: boolean;
};

export class TangentLineToCircleModel extends BaseModel {
    point: TPoint;
    circle: TEllipse;
    forwardDirection: boolean;

    constructor(data: TTangentLine, id: number) {
        super(id);

        this.point = copyPoint(data.point);
        this.circle = copyEllipse(data.circle);
        this.forwardDirection = data.forwardDirection;
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            point: observable,
            circle: observable,
            forwardDirection: observable,
            pointOnCircle: computed,
            virtualTangentLine: computed,
        });
    }

    get pointOnCircle(): TPoint | null {
        try {
            const [first, second] = getTangentPoints(this.point, this.circle);
            return this.forwardDirection ? first : second;
        } catch {
            // точка лежит внутри окружности, и касательные нельзя построить
            return null;
        }
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withTangentLine(this);
    }

    checkIsTangent(): this is NonNullableObject<Pick<this, "virtualTangentLine" | "pointOnCircle">> {
        return !!this.virtualTangentLine;
    }

    static create(data: TTangentLine) {
        return new TangentLineToCircleModel(data, generateId());
    }

    get virtualTangentLine(): TFragment | null {
        if (!this.pointOnCircle) return null;
        return getLineFromFragment(this.point, this.pointOnCircle);
    }
}
