import axios from "axios";

import { isZeroLengthMathExpr } from "@viuch/math-editor";
import { createSerializedState } from "@viuch/math-editor/utils/serialization";
import { compareNumbers } from "@viuch/shared/utils/data";

import type { TRawTaskGenerationProgress, TTaskGenerationProgress, TTaskRequest, TTaskResponse } from "./types";
import type { AxiosResponse } from "axios";

import { agent } from "../../agent";
import { Task } from "../../entities/tasks";
import { mapPrimaryHints, serializePrimaryHints } from "../../entities/tasks/utils";
import { mapTaskInstrumentSettings } from "../../serializers/instrumentSettings/mapTaskInstrumentSettings";
import { serializeTaskInstrumentSettings } from "../../serializers/instrumentSettings/serializeTaskInstrumentSettings";

import { TaskError } from "./errors";
import { mapAnswer, mapVariable } from "./utils/mapVariable";
import { serializeAnswer, serializeVariable } from "./utils/serializeVariable";

export const mapTaskGenerationProgress = (data: TRawTaskGenerationProgress): TTaskGenerationProgress => {
    const { estimate, generated: generated, id, is_completed } = data;

    return {
        estimate,
        generated,
        id,
        isCompleted: is_completed,
    };
};

export class TasksService {
    async loadTasksByTheme(themeId: number): Promise<Task[]> {
        const { data }: AxiosResponse<TTaskResponse[]> = await agent.get("/tasks", {
            params: { theme: String(themeId) },
        });

        return data.sort((l, r) => compareNumbers(l.id, r.id)).map(mapTask);
    }

    private serializeTask = (task: Task): TTaskRequest => {
        return {
            variables: task.variables.map(serializeVariable),
            formula: task.formula,
            theme: task.theme,
            answers: task.answers.map(serializeAnswer),
            instrument_settings: task.instruments.map(serializeTaskInstrumentSettings),
            problems: task.problemIds,
            primary_hints: task.primaryHints ? serializePrimaryHints(task.primaryHints) : null,
            variant_generation_limit: task.variantGenerationLimit,
            task_text: isZeroLengthMathExpr(task.overrideTaskText) ? null : task.overrideTaskText,
            is_verified: task.isVerified,
        };
    };

    async removeTask(task: Task) {
        await agent.delete(`/tasks/${task.id}/`);
    }

    async createTask(task: Task) {
        try {
            const taskRequest = this.serializeTask(task);
            const { data }: AxiosResponse<TTaskResponse> = await agent.post("/tasks/", taskRequest);

            return mapTask(data);
        } catch (e) {
            this.handleTaskError(e);
        }
    }

    private handleTaskError(e: unknown): never {
        if (axios.isAxiosError(e) && e.response) {
            if (typeof e.response.data === "string") {
                throw new TaskError(e.message, [e.response.data]);
            }
            if (typeof e.response.data === "object") {
                throw new TaskError(e.message, e.response.data);
            }
        }

        console.error(e);
        throw new TaskError((<Error>e).message, {});
    }

    async updateTask(task: Task) {
        try {
            const taskRequest = this.serializeTask(task);

            const { data }: AxiosResponse<TTaskResponse> = await agent.patch(`/tasks/${task.id}/`, taskRequest);
            return mapTask(data);
        } catch (e) {
            this.handleTaskError(e);
        }
    }

    async loadTaskById(id: number | string): Promise<Task> {
        try {
            const { data } = await agent.get<TTaskResponse>(`/tasks/${id}/`);
            return mapTask(data);
        } catch (e) {
            this.handleTaskError(e);
        }
    }

    async loadTaskGenerationProgress(id: number | string): Promise<TTaskGenerationProgress> {
        try {
            const { data } = await agent.get<TRawTaskGenerationProgress>(`/tasks/${id}/generation_progress/`);

            return mapTaskGenerationProgress(data);
        } catch (e) {
            this.handleTaskError(e);
        }
    }
}

export const mapTask = (data: TTaskResponse): Task => {
    return new Task(
        {
            theme: data.theme,
            formula: data.formula,
            answers: data.answers.map(mapAnswer),
            variables: data.variables.map(mapVariable),
            instruments: data.instrument_settings.map(mapTaskInstrumentSettings),
            problemIds: data.problems.slice(),
            primaryHints: data.primary_hints ? mapPrimaryHints(data.primary_hints) : null,
            variantGenerationLimit: data.variant_generation_limit,
            overrideTaskText: data.task_text ?? createSerializedState(),
            isVerified: data.is_verified,
        },
        data.id
    );
};
