import cn from "classnames";
import React, { type Ref } from "react";

import styles from "./CheckboxInput.module.scss";

type Props = {
    type: "checkbox" | "radio";
    checked: boolean;
    onChange: (checked: boolean) => void;
    inputRef?: Ref<HTMLInputElement>;
    labelBefore?: string;
    labelAfter?: string;
    className?: string;
    inline?: boolean;
    disabled?: boolean;
    noSpace?: boolean;
    fitContent?: boolean;
};

export const CheckboxInput = (props: Props) => {
    const {
        type,
        className,
        checked,
        onChange,
        labelAfter,
        labelBefore,
        inline,
        disabled,
        noSpace,
        fitContent,
        inputRef,
    } = props;

    return (
        <label
            className={cn(
                className,
                styles.wrapper,
                inline && styles.inline,
                noSpace && styles.noSpace,
                fitContent && styles.fitContent
            )}
        >
            {labelBefore && <span className={styles.label}>{labelBefore}</span>}
            <input
                ref={inputRef}
                type={type}
                checked={checked}
                onChange={() => onChange(!checked)}
                className={styles.checkbox}
                disabled={disabled}
            />
            {labelAfter && <span className={cn(styles.label)}>{labelAfter}</span>}
        </label>
    );
};
