import { makeObservable, observable } from "mobx";

import type { IElementVisitor } from "../BaseElement";
import type { ElementColor } from "../ElementColor";

import { BaseElement } from "../BaseElement";

export interface IDotElement {
    id: string;
    x: number;
    y: number;
    color?: ElementColor;
    overrideRenderOrder: number;
    locked?: boolean;
}

export class DotElement extends BaseElement implements IDotElement {
    x: number;
    y: number;

    constructor(data: IDotElement) {
        super(data);
        this.x = data.x;
        this.y = data.y;

        makeObservable(this, {
            x: observable,
            y: observable,
        });
    }

    static create(data: IDotElement) {
        return new DotElement(data);
    }

    accept<R>(visitor: IElementVisitor<R>): R {
        return visitor.withDot(this);
    }
}
