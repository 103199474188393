import type { BaseFlow } from "../flows";
import type { ToolbarButton } from "../toolbar";

import { CircumscribedCircleFlow, EllipseFlow, InscribedCircleFlow, CircleFlow } from "../flows/circles";
import { EqualAnglesFlow, EqualSegmentsFlow } from "../flows/constraints";
import { CreateDotFlow } from "../flows/create-dot";
import { CreateFragmentFlow, MiddleFragmentInteractiveFlow, ParallelFragmentFlow } from "../flows/create-fragment";
import { CreateLabelAngleInteractiveFlow } from "../flows/create-label-angle";
import { CreateDotLabelInteractiveFlow } from "../flows/create-label-dot";
import { LabelFragmentInteractiveFlow } from "../flows/create-label-fragment";
import { ConstraintLineFlow, CreateLineFlow } from "../flows/create-line";
import { CreateRectFlow, CreateSquareFlow, ParallelogramInteractiveFlow } from "../flows/create-rect";
import { CreateRightAngleInteractiveFlow } from "../flows/create-right-angle";
import { RhombFlow, TrapezeFlow, TriangleFlow } from "../flows/derived";
import { InteractiveAltitudeFlow, InteractiveBisectionFlow, InteractiveMedianFlow } from "../flows/geometry";
import { TangentToCircleInteractiveFlow } from "../flows/tangent";
import { TangentEllipsePointFlow } from "../flows/tangent/TangentEllipsePointFlow";
import { ButtonStates } from "../toolbar";
import { ToolbarMenuBuilder } from "../toolbar/builder";
import { createMainMenuButtons } from "../toolbar/builder/utils";

export function handleToolbarButtons(flow: BaseFlow, button: ToolbarButton): void {
    const { key } = button;
    switch (key) {
        case "cursor":
        case "cancel":
            return flow.nextFlow();
        case "undo":
            return flow.nextFlow();
        case "redo":
            flow.nextFlow();
            flow.history.redo();
            flow.nextFlow();
            return;
        case "dot":
            return flow.nextFlow(CreateDotFlow.create);
        case "fragment":
        case "fragment-dash":
        case "vector":
        case "vector-dash":
            return flow.nextFlow((data) => new CreateFragmentFlow(data, key));
        case "line":
            return flow.nextFlow(CreateLineFlow.create);
        case "rect":
            return flow.nextFlow(CreateRectFlow.create);
        case "square":
            return flow.nextFlow(CreateSquareFlow.create);
        case "parallelogram":
            return flow.nextFlow(ParallelogramInteractiveFlow.create);
        case "perpendicular":
            return flow.nextFlow((data) => new ConstraintLineFlow(data, Math.PI / 2));
        case "parallel":
            return flow.nextFlow((data) => new ConstraintLineFlow(data, 0));
        case "label-dot":
            return flow.nextFlow(CreateDotLabelInteractiveFlow.create);
        case "label-angle":
            return flow.nextFlow((data) => new CreateLabelAngleInteractiveFlow(data, false));
        case "equal-angles":
            return flow.nextFlow(EqualAnglesFlow.create);
        case "equal-segments":
            return flow.nextFlow(EqualSegmentsFlow.create);
        case "ellipse":
            return flow.nextFlow(EllipseFlow.create);
        case "circle":
            return flow.nextFlow(CircleFlow.create);
        case "circle-inscribed":
            return flow.nextFlow(InscribedCircleFlow.create);
        case "circle-circumscribed":
            return flow.nextFlow(CircumscribedCircleFlow.create);
        case "triangle":
            return flow.nextFlow(TriangleFlow.create);
        case "rhombus":
            return flow.nextFlow(RhombFlow.create);
        case "trapeze":
            return flow.nextFlow(TrapezeFlow.create);
        case "bisection":
            return flow.nextFlow(InteractiveBisectionFlow.create);
        case "median":
            return flow.nextFlow(InteractiveMedianFlow.create);
        case "altitude":
            return flow.nextFlow(InteractiveAltitudeFlow.create);
        case "label-segment":
            return flow.nextFlow(LabelFragmentInteractiveFlow.create);
        case "middle-line":
            return flow.nextFlow(MiddleFragmentInteractiveFlow.create);
        case "tangent":
            return flow.nextFlow(TangentToCircleInteractiveFlow.create);
        case "tangent-point":
            return flow.nextFlow(TangentEllipsePointFlow.create);
        case "parallel-fragment":
            return flow.nextFlow(ParallelFragmentFlow.create);
        case "right-angle":
            return flow.nextFlow((data) => new CreateRightAngleInteractiveFlow(data));
    }
}

export function createDefaultToolbarMenuBuilder(): ToolbarMenuBuilder {
    return new ToolbarMenuBuilder("main")
        .addButtons(createMainMenuButtons())
        .setButtonStateAll(ButtonStates.disabled)

        .setButtonState(ButtonStates.default, ["cursor"])

        .setButtonState(ButtonStates.default, ["fragments"])
        .setButtonState(ButtonStates.default, ["fragments", "dot"])
        .setButtonState(ButtonStates.default, ["fragments", "fragment"])
        .setButtonState(ButtonStates.default, ["fragments", "fragment-dash"])
        .setButtonState(ButtonStates.default, ["fragments", "vector"])
        .setButtonState(ButtonStates.default, ["fragments", "vector-dash"])
        .setButtonState(ButtonStates.default, ["fragments", "line"])
        .setButtonState(ButtonStates.default, ["fragments", "parallel"])
        .setButtonState(ButtonStates.default, ["fragments", "perpendicular"])
        .setButtonState(ButtonStates.default, ["fragments", "bisection"])
        .setButtonState(ButtonStates.default, ["fragments", "median"])
        .setButtonState(ButtonStates.default, ["fragments", "altitude"])
        .setButtonState(ButtonStates.default, ["fragments", "middle-line"])
        .setButtonState(ButtonStates.default, ["fragments", "tangent"])
        .setButtonState(ButtonStates.default, ["fragments", "tangent-point"])
        .setButtonState(ButtonStates.default, ["fragments", "parallel-fragment"])

        .setButtonState(ButtonStates.default, ["triangle"])

        .setButtonState(ButtonStates.default, ["squares"])
        .setButtonState(ButtonStates.default, ["squares", "square"])
        .setButtonState(ButtonStates.default, ["squares", "rect"])
        .setButtonState(ButtonStates.default, ["squares", "trapeze"])
        .setButtonState(ButtonStates.default, ["squares", "parallelogram"])
        .setButtonState(ButtonStates.default, ["squares", "rhombus"])

        .setButtonState(ButtonStates.default, ["circles"])
        .setButtonState(ButtonStates.default, ["circles", "ellipse"])
        .setButtonState(ButtonStates.default, ["circles", "circle"])
        .setButtonState(ButtonStates.default, ["circles", "circle-inscribed"])
        .setButtonState(ButtonStates.default, ["circles", "circle-circumscribed"])

        .setButtonState(ButtonStates.default, ["labels"])
        .setButtonState(ButtonStates.default, ["labels", "label-dot"])
        .setButtonState(ButtonStates.default, ["labels", "label-angle"])
        .setButtonState(ButtonStates.default, ["labels", "label-segment"])
        .setButtonState(ButtonStates.default, ["labels", "equal-angles"])
        .setButtonState(ButtonStates.default, ["labels", "right-angle"])
        .setButtonState(ButtonStates.default, ["labels", "equal-segments"]);
}
