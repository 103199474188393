import { computed, makeObservable } from "mobx";

import { compareNumbers, generateId } from "@viuch/shared/utils/data";

import type { FlowManager } from "./FlowManager";
import type { Figure2D } from "../../entities/Figure2D";
import type { BaseElement } from "../elements";
import type { Figure2DController } from "../Figure2DController";
import type { HistoryManager } from "../history";
import type { SelectionController } from "../selection";
import type { SnapToGridService } from "../services";
import type { ActionsManager } from "../services/actions";
import type { ToolbarButton, ToolbarMenu } from "../toolbar";
import type { TooltipMenu } from "../toolbar/tooltip";
import type { ViewportController } from "../viewport";
import type { IUserPointer } from "../viewport/types";
import type React from "react";

import { createDefaultToolbarMenuBuilder, handleToolbarButtons } from "../utils/toolbar";

import { createDefaultFlow, getElementPositionHash } from "./utils";

export abstract class BaseFlow {
    protected readonly controller: Figure2DController;
    readonly id = generateId();

    protected constructor(controller: Figure2DController) {
        this.controller = controller;

        makeObservable(this, {
            elements: computed,
        });
    }

    get flows(): FlowManager {
        return this.controller.flows;
    }

    get viewport(): ViewportController {
        return this.controller.viewport;
    }

    get elements(): BaseElement[] {
        const allHashes = new Set<string>();

        const elementsReverse = this.renderElements().sort(sortRenderElements);

        for (const element of elementsReverse) {
            let isVisible = true;

            for (const currentHash of getElementPositionHash(element)) {
                if (allHashes.has(currentHash)) {
                    isVisible = false;
                }
                allHashes.add(currentHash);
            }

            if (isVisible) {
                element.show();
            } else {
                element.hide();
            }
        }

        return elementsReverse.reverse();

        function sortRenderElements(a: BaseElement, b: BaseElement): number {
            return compareNumbers(a.overrideRenderOrder, b.overrideRenderOrder);
        }
    }

    get figure(): Figure2D {
        return this.controller.figure;
    }

    get selection(): SelectionController {
        return this.controller.selection;
    }

    get actions(): ActionsManager {
        return this.controller.actions;
    }

    get history(): HistoryManager {
        return this.controller.history;
    }

    get snap(): SnapToGridService {
        return this.controller.snap;
    }

    nextFlow(createState: (data: Figure2DController) => BaseFlow): void;
    nextFlow(): void;
    nextFlow(createState?: (data: Figure2DController) => BaseFlow): void {
        const state = createState?.(this.controller) || createDefaultFlow(this.controller);
        this.flows.next(state);
    }

    protected abstract renderElements(): BaseElement[];

    abstract attach(): void;

    abstract dispose(): void;

    handleElementPointerEvent(e: React.PointerEvent, element: BaseElement, pointer: IUserPointer): void;
    handleElementPointerEvent(): void {}

    handleViewportPointerEvent(e: React.PointerEvent, pointer: IUserPointer): void;
    handleViewportPointerEvent(): void {}

    handleKeyEvent(e: React.KeyboardEvent): void {
        const { key, ctrlKey, altKey, shiftKey } = e;

        switch (true) {
            case key === "Escape" && !ctrlKey && !altKey && !shiftKey:
                this.nextFlow();
                return;

            case ctrlKey && ["z", "я"].includes(key) && !shiftKey && !altKey:
                e.preventDefault();
                this.nextFlow();
                return;
        }
    }

    getToolbarMenu(): ToolbarMenu {
        return createDefaultToolbarMenuBuilder().build();
    }

    getTooltipMenu(): TooltipMenu | null {
        return null;
    }

    handleToolbarButtonClick(menu: ToolbarMenu, button: ToolbarButton): void {
        handleToolbarButtons(this, button);
    }
}
