import { getLineAngle } from "@viuch/geometry-lib/angles";
import { areSamePoints, isPointOnFragment, isPointOnLine } from "@viuch/geometry-lib/check-geometry";
import {
    copyEllipse,
    copyFragment,
    createFragment,
    createLine,
    fragmentToLine,
    lineToFragment,
} from "@viuch/geometry-lib/factories";
import { assert } from "@viuch/utils/debug";

import type { BaseModel, IModelVisitor } from "../../models/BaseModel";
import type { TAngle, TBasis, TEllipse, TFragment, TLine, TPoint, TVector } from "@viuch/geometry-lib/types";

const sn = (n: number) => n.toFixed(7);
const sp = (p: TPoint) => `${sn(p.x)}__${sn(p.y)}`;
const sa = (a: TAngle) => `${sp(a.start)}__${sp(a.vertex)}__${sp(a.end)}`;
const sf = ({ a, b }: TFragment) => `${sp(a)}__${sp(b)}`;
const sv = ({ from, to }: TVector) => `${sp(from)}__${sp(to)}`;
const sb = ({ fragment, offset }: TBasis) => `${sf(fragment)}__${offset ? sp(offset) : "-"}`;
const se = ({ center, rx, ry }: TEllipse) => `${sp(center)}__${sn(rx)}__${sn(ry)}`;
const sBit = (bool: boolean) => (bool ? "1" : "0");

export const equalPositionHashVisitor: IModelVisitor<string> = {
    withPoint: (p) => `point__${sp(p)}`,
    withFragment: (f) => `frag__${sf(f)}`,
    withParallelFragment: (f) => `parallelFrag__${sf(f)}__${sf(f.base)}`,
    withLabelPoint: (l) => `labelPoint__${sp(l)}`,
    withLabelFragment: (l) => `labelFragment__${sf(l)}`,
    withRightAngle: (l) => `rightAngle__${sa(l)}`,
    withLabelAngle: (l) => `labelAngle__${sa(l)}`,
    withLine: (l) => `line__${sf(l)}`,
    withConstraintLine: (l) =>
        `cLine__${sp(l.vertex)}__${sp(l.constraintA)}__${sp(l.constraintB)}__${sn(l.offsetAngle)}`,
    withComputedIntersectionPoints: (model) => `computedIntersectionPoints__${model.id}`,
    withBisection: (b) => `bisection__${sa(b.angle)}__${sb(b.basis)}`,
    withMedian: (b) => `median__${sp(b.vertex)}__${sf(b.fragment)}`,
    withAltitude: (a) => `altitude__${sp(a.vertex)}__${sb(a.basis)}`,
    withEqualAngles: (ea) => `equalAngles__${ea.id}`,
    withEqualSegments: (es) => `equalSegments__${es.id}`,
    withEllipse: (e) => `ellipse__${se(e)}`,
    withInscribedCircle: (c) => `inscribedCircle__${c.id}`,
    withCircumscribedCircle: (c) => `circumscribedCircle__${c.id}`,
    withTangentLine: (tangent) =>
        `tangent__${se(tangent.circle)}__${sp(tangent.point)}__${sBit(tangent.forwardDirection)}`,
    withMiddleLine: (middleLine) => `middleLine__${sf(middleLine.base1)}__${sf(middleLine.base2)}`,
    withTangentLineOnCircle: (tangent) => `tangentOnCircle__${se(tangent.ellipse)}__${sp(tangent.touchPoint)}`,
    withFragmentDashed: (f) => `frag-dashed__${sf(f)}`,
    withVector: (v) => `vector__${sv(v)}`,
    withVectorDashed: (v) => `vector-dashed__${sv(v)}`,
};

export type TFragmentWithAngle = TFragment & { angle: number };

export const fragmentsForAnglesVisitor: IModelVisitor<TFragmentWithAngle[]> = {
    withFragment: (fragment) => {
        const angle = getLineAngle(createLine(fragment.a, fragment.b), true);
        return [{ ...copyFragment(fragment), angle }];
    },
    withParallelFragment: (fragment) => {
        const angle = getLineAngle(createLine(fragment.a, fragment.b), true);
        return [{ ...copyFragment(fragment), angle }];
    },
    withLine: (line) => {
        const angle = getLineAngle(createLine(line.a, line.b), true);
        return [{ ...copyFragment(line), angle }];
    },
    withBisection: ({ bisectionFragment }) => {
        if (!bisectionFragment) return [];
        const angle = getLineAngle(createLine(bisectionFragment), true);
        return [{ ...copyFragment(bisectionFragment), angle }];
    },
    withMedian: ({ medianFragment }) => {
        const angle = getLineAngle(createLine(medianFragment), true);
        return [{ ...copyFragment(medianFragment), angle }];
    },
    withAltitude: ({ altitudeFragment, angles: { angleAltitude } }) => {
        return [{ ...copyFragment(altitudeFragment), angle: angleAltitude }];
    },
    withTangentLine: (tangent) => {
        if (!tangent.checkIsTangent()) return [];
        const fragment = createFragment(tangent.point, tangent.pointOnCircle);
        const angle = getLineAngle(createLine(fragment), true);
        return [{ ...fragment, angle }];
    },
    withMiddleLine: ({ middleLineFragment }) => {
        const angle = getLineAngle(createLine(middleLineFragment.a, middleLineFragment.b), true);
        return [{ ...copyFragment(middleLineFragment), angle }];
    },
    withPoint: () => [],
    withFragmentDashed: (fragment) => {
        const angle = getLineAngle(createLine(fragment.a, fragment.b), true);
        return [{ ...copyFragment(fragment), angle }];
    },
    withVector: (vector) => {
        const angle = getLineAngle(vector.toLine(), true);
        return [{ ...vector.toFragment(), angle }];
    },
    withVectorDashed: (vector) => {
        const angle = getLineAngle(vector.toLine(), true);
        return [{ ...vector.toFragment(), angle }];
    },
    withLabelPoint: () => [],
    withLabelFragment: () => [],
    withRightAngle: () => [],
    withLabelAngle: () => [],
    withConstraintLine: (line) => [],
    withComputedIntersectionPoints: () => [],
    withEqualAngles: () => [],
    withEqualSegments: () => [],
    withEllipse: () => [],
    withInscribedCircle: () => [],
    withCircumscribedCircle: () => [],
    withTangentLineOnCircle: (tangent) => [],
};

export const getFragmentsWithAngles = (models: BaseModel[]): TFragmentWithAngle[] => {
    return models.flatMap((model) => model.accept(fragmentsForAnglesVisitor)).sort((l, r) => l.angle - r.angle);
};

const formatAngle = (angle: number) => (angle + Math.PI * 4) % (Math.PI * 2);

export function filterFragmentsOfPoint(fragments: TFragmentWithAngle[], point: TPoint): TFragmentWithAngle[] {
    return fragments
        .flatMap((fragment) => {
            const { a, b, angle } = fragment;

            if (areSamePoints(a, point)) {
                return [{ a, b, angle: formatAngle(angle) }];
            }
            if (areSamePoints(b, point)) {
                return [{ a: b, b: a, angle: formatAngle(angle + Math.PI) }];
            }
            return [];
        })
        .sort((l, r) => l.angle - r.angle);
}

export type TFragmentsDiff = {
    angleDiff: number;
    left: TFragment & { angle: number };
    right: TFragment & { angle: number };
};

export const createFragmentsAngleDiffs = (fragments: TFragmentWithAngle[]): TFragmentsDiff[] =>
    fragments
        .map((fragment, i, all) => {
            if (i === all.length - 1) {
                const next = all[0];
                const angleDiff = next.angle + 2 * Math.PI - fragment.angle;
                assert(angleDiff >= 0, angleDiff.toFixed(5));
                return { left: fragment, right: next, angleDiff };
            }
            const next = all[i + 1];
            const angleDiff = next.angle - fragment.angle;
            assert(angleDiff >= 0, angleDiff.toFixed(5));
            return { left: fragment, right: next, angleDiff };
        })
        .sort((l, r) => l.angleDiff - r.angleDiff);

export const intersectionFragmentsAndLinesVisitor: IModelVisitor<Array<TFragment | TLine | TEllipse>> = {
    withLabelPoint: () => [],
    withPoint: () => [],
    withLabelFragment: () => [],
    withRightAngle: () => [],
    withLabelAngle: () => [],
    withComputedIntersectionPoints: () => [],
    withLine: (line) => [line.toVirtualLine()],
    withFragment: (fragment) => [fragment.toFragment()],
    withFragmentDashed: () => [],
    withVector: (vector) => [vector.toFragment()],
    withVectorDashed: () => [],
    withParallelFragment: (fragment) => [copyFragment(fragment)],
    withConstraintLine: (line) => [line.toVirtualLine()],
    withMedian: (median) => [median.toMedianFragment()],
    withBisection: (bisection) => {
        const f = bisection.toBisectionFragment();
        return f ? [f] : [];
    },
    withAltitude: (altitude) => [altitude.toAltitudeFragment()],
    withEqualAngles: () => [],
    withEqualSegments: () => [],
    withEllipse: (ellipse) => [copyEllipse(ellipse)],
    withInscribedCircle: (circle) => (circle.isEllipse() ? [copyEllipse(circle)] : []),
    withCircumscribedCircle: (circle) => (circle.isEllipse() ? [copyEllipse(circle)] : []),
    withTangentLine: (tangent) => (tangent.checkIsTangent() ? [createLine(tangent.virtualTangentLine)] : []),
    withMiddleLine: (middleLine) => [copyFragment(middleLine.middleLineFragment)],
    withTangentLineOnCircle: (tangent) => (tangent.hasTangent() ? [createLine(tangent.virtualTangentFragment)] : []),
};

export const getEqualFragmentsVisitor: IModelVisitor<TFragment[]> = {
    withMedian: ({ fragment, midPoint }) => [
        createFragment(fragment.a, midPoint),
        createFragment(midPoint, fragment.b),
    ],
    withEqualSegments: ({ segments }) => segments.map(copyFragment),
    withPoint: () => [],
    withFragment: () => [],
    withFragmentDashed: () => [],
    withVector: () => [],
    withVectorDashed: () => [],
    withLabelPoint: () => [],
    withLabelFragment: () => [],
    withRightAngle: () => [],
    withLabelAngle: () => [],
    withLine: () => [],
    withConstraintLine: () => [],
    withComputedIntersectionPoints: () => [],
    withBisection: () => [],
    withAltitude: () => [],
    withEqualAngles: () => [],
    withEllipse: () => [],
    withInscribedCircle: () => [],
    withCircumscribedCircle: () => [],
    withTangentLine: () => [],
    withMiddleLine: () => [],
    withTangentLineOnCircle: () => [],
    withParallelFragment: () => [],
};

export function getAllFragmentAngleValuesAtPoint(point: TPoint, model: BaseModel) {
    const getAngle = (a: TPoint, b: TPoint) => getLineAngle(createLine(a, b), true);

    function* getFragmentAngles(fragment: TFragment): Iterable<number> {
        if (!isPointOnFragment(fragment, point)) return;

        const { a, b } = fragment;
        if (areSamePoints(a, b)) return;

        const pointIsA = areSamePoints(a, point);
        const pointIsB = areSamePoints(b, point);

        if (!pointIsB) {
            yield getAngle(point, b);
        }

        if (!pointIsA) {
            yield getAngle(point, a);
        }
    }

    function getVectorAngles(v: TVector): Iterable<number> {
        return getFragmentAngles(createFragment(v.from, v.to));
    }

    function* getLineAngles(line: TLine): Iterable<number> {
        if (!isPointOnLine(line, point)) return;

        const { a, b } = lineToFragment(line);
        if (areSamePoints(a, b)) return;
        const angle = getAngle(a, b);
        yield angle;

        yield (angle + Math.PI) % (2 * Math.PI);
    }

    const visitor: IModelVisitor<Iterable<number>> = {
        withPoint: () => [],
        withFragment: getFragmentAngles,
        withFragmentDashed: getFragmentAngles,
        withVector: getVectorAngles,
        withVectorDashed: getVectorAngles,
        withLabelPoint: () => [],
        withLabelFragment: () => [],
        withRightAngle: () => [],
        withLabelAngle: () => [],
        withLine: (l) => getLineAngles(l.toLine()),
        withConstraintLine: (l) => getLineAngles(l.toVirtualLine()),
        withComputedIntersectionPoints: () => [],
        withBisection: (bisection) => {
            const fragment = bisection.toBisectionFragment();
            return fragment ? getFragmentAngles(fragment) : [];
        },
        withMedian: (median) => {
            const fragment = median.toMedianFragment();
            return fragment ? getFragmentAngles(fragment) : [];
        },
        withAltitude: (altitude) => {
            const fragment = altitude.toAltitudeFragment();
            return fragment ? getFragmentAngles(fragment) : [];
        },
        withEqualAngles: () => [],
        withEqualSegments: () => [],
        withEllipse: () => [],
        withInscribedCircle: () => [],
        withCircumscribedCircle: () => [],
        withTangentLine: (tangent) => {
            const { virtualTangentLine } = tangent;
            return virtualTangentLine ? getLineAngles(fragmentToLine(virtualTangentLine)) : [];
        },
        withMiddleLine: (middleLine) => getFragmentAngles(middleLine.middleLineFragment),
        withTangentLineOnCircle: (tangent) => {
            const { virtualTangentFragment } = tangent;
            return virtualTangentFragment ? getLineAngles(fragmentToLine(virtualTangentFragment)) : [];
        },
        withParallelFragment: (fragment) => getFragmentAngles(fragment),
    };

    const angles = [...model.accept(visitor)].flat();
    return [...new Set(angles)];
}
