import { compareFloats } from "@viuch/geometry-lib/equate";
import { copyEllipse } from "@viuch/geometry-lib/factories";
import { subtractVectors, vectorLength } from "@viuch/geometry-lib/vectors";

import type { Figure2D } from "../../../entities/Figure2D";
import type { TEllipse, TPoint } from "@viuch/geometry-lib/types";

/**
 * Находит все круги на чертеже.
 *
 * Эллипсы с разными радиусами не возвращаются
 */
export function getAllCircles(figure: Figure2D): TEllipse[] {
    return figure.models.flatMap<TEllipse>((model) => {
        return model.accept<TEllipse[]>({
            withEllipse: (ellipse) => [copyEllipse(ellipse)],
            withInscribedCircle: (circle) => (circle.isEllipse() ? [copyEllipse(circle)] : []),
            withCircumscribedCircle: (circle) => (circle.isEllipse() ? [copyEllipse(circle)] : []),
            withPoint: () => [],
            withFragment: () => [],
            withFragmentDashed: () => [],
            withVector: () => [],
            withVectorDashed: () => [],
            withLabelPoint: () => [],
            withLabelFragment: () => [],
            withRightAngle: () => [],
            withLabelAngle: () => [],
            withLine: () => [],
            withConstraintLine: () => [],
            withComputedIntersectionPoints: () => [],
            withBisection: () => [],
            withMedian: () => [],
            withAltitude: () => [],
            withEqualAngles: () => [],
            withEqualSegments: () => [],
            withTangentLine: () => [],
            withMiddleLine: () => [],
            withTangentLineOnCircle: () => [],
            withParallelFragment: () => [],
        });
    });
}

export function checkPointInCircle(circle: TEllipse, point: TPoint, equalResult = false): boolean {
    const distance = vectorLength(subtractVectors(circle.center, point));

    switch (compareFloats(distance, circle.rx)) {
        case -1:
            return true;
        case 1:
            return false;
        case 0:
        default:
            return equalResult;
    }
}
