import { action, makeObservable, observable } from "mobx";

import { InputService } from "@viuch/math-editor";

import type { ElementColor } from "../ElementColor";
import type { TSerializedState } from "@viuch/math-editor";
import type { TAnyAction } from "@viuch/math-editor/types";

import { BaseElement } from "../BaseElement";

export interface ILabelElement {
    id: string;
    value: TSerializedState;
    rotationAngle: number;
    directionAngle: number;
    altOrigin?: boolean;
    color?: ElementColor;
    isEditable?: boolean;
    overrideRenderOrder: number;
    notifyAction?: (inputService: InputService, action: TAnyAction) => void;
    notifyBlur?: (inputService: InputService) => void;
    locked?: boolean;
}

export abstract class BaseLabelElement extends BaseElement implements ILabelElement {
    readonly isEditable: boolean;

    value: TSerializedState;
    directionAngle: number;
    rotationAngle: number;
    altOrigin: boolean;
    notifyAction?: (inputService: InputService, action: TAnyAction) => void;
    notifyBlur?: (inputService: InputService) => void;

    readonly inputService: InputService;

    protected constructor(data: ILabelElement) {
        super(data);

        this.value = data.value;
        this.directionAngle = data.directionAngle;
        this.rotationAngle = data.rotationAngle;
        this.isEditable = data.isEditable ?? false;
        this.altOrigin = !!data.altOrigin;

        this.inputService = new InputService({
            focus: this.isEditable,
            initialState: this.value,
            isReadOnly: !this.isEditable,
            disableRuntimePlaceholders: !this.isEditable,
            notifyAction: data.notifyAction,
        });

        makeObservable(this, {
            value: observable.ref,
            directionAngle: observable.ref,
            rotationAngle: observable.ref,
            altOrigin: observable.ref,
            toggleAltOrigin: action.bound,
        });
    }

    abstract get x(): number;
    abstract get y(): number;

    toggleAltOrigin() {
        this.altOrigin = !this.altOrigin;
    }
}
