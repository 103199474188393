import { makeObservable, observable } from "mobx";

import { copyPoint } from "@viuch/geometry-lib/factories";
import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TModelStyle } from "../modelStyle";
import type { TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IEllipseModel {
    center: TPoint;
    rx: number;
    ry: number;
    style: TModelStyle | null;
    is_editable?: boolean;
}

export class EllipseModel extends BaseModel implements IEllipseModel {
    center: TPoint;
    rx: number;
    ry: number;
    style: TModelStyle | null;

    constructor(data: IEllipseModel, id: number) {
        super(id);

        this.center = copyPoint(data.center);
        this.rx = data.rx;
        this.ry = data.ry;
        this.style = data.style;
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            center: observable,
            rx: observable,
            ry: observable,
            style: observable,
        });
    }

    static create(data: IEllipseModel) {
        return new EllipseModel(data, generateId());
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withEllipse(this);
    }
}
