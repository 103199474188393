import { getAngles } from "@viuch/geometry-lib/angles";
import { createPoint } from "@viuch/geometry-lib/factories";
import { getPointHash } from "@viuch/geometry-lib/hashing";
import { assert } from "@viuch/utils/debug";

import type { BaseFlow } from "./BaseFlow";
import type { Figure2D } from "../../entities/Figure2D";
import type { BaseElement } from "../elements";
import type { IElementVisitor } from "../elements/BaseElement";
import type { Figure2DController } from "../Figure2DController";
import type { BaseModel } from "../models";
import type { IModelVisitor } from "../models/BaseModel";
import type { TModelStyle } from "../models/modelStyle";
import type { TPoint } from "@viuch/geometry-lib/types";

import { AngleElement } from "../elements/angle";
import { DotElement } from "../elements/dot";
import { ElementColor } from "../elements/ElementColor";
import { EllipseElement } from "../elements/ellipse";
import { LabelAngleElement, LabelDotElement, LabelFragmentElement } from "../elements/label-text";
import { LineElement } from "../elements/line";
import { VectorElement } from "../elements/line/VectorElement";
import { StrokeElement } from "../elements/stroke";

import { DefaultFlow } from "./default";
import { getAltitudeAngles } from "./geometry/utils";

type TTransformColor = (color: ElementColor) => ElementColor;

export function createDefaultFlow(data: Figure2DController): BaseFlow {
    return new DefaultFlow(data);
}

export const z = {
    points: { default: 1050, virtual: 1060, priority: 1030 },
    pieces: { default: 1150, virtual: 1160, priority: 1130 },
    fragments: { default: 1250, virtual: 1260, priority: 1230 },
    lines: { default: 1350, virtual: 1360, priority: 1330 },
    circles: { default: 1450, priority: 1430 },
    labels: { default: 1550, priority: 1530 },
};

export const createMapModelToElements = (transformColor: TTransformColor = nullColorTransformer) => {
    const defaultColor = transformColor(ElementColor.Default);
    const attentionColor = transformColor(ElementColor.Selected);
    const virtualColor = transformColor(ElementColor.Default.withColor("#ffa36b"));
    const transparentColor = transformColor(ElementColor.Transparent);

    function getDefaultColor(style: TModelStyle | null, locked?: boolean): ElementColor {
        switch (style) {
            case "attention":
                return locked ? attentionColor.withOpacity(0.7) : attentionColor;
        }
        return locked ? defaultColor.withOpacity(0.7) : defaultColor;
    }

    function getVirtualColor(style: TModelStyle | null, locked?: boolean): ElementColor {
        switch (style) {
            case "attention":
                return locked ? attentionColor.withOpacity(0.7) : attentionColor;
        }
        return locked ? virtualColor.withOpacity(0.7) : virtualColor;
    }

    const mapModelToElementsVisitor: IModelVisitor<BaseElement[]> = {
        withPoint: (model) => [
            new DotElement({
                id: `point_${model.id}__dot`,
                x: model.x,
                y: model.y,
                color: getDefaultColor(model.style, !model.is_editable),
                overrideRenderOrder: z.points.default,
                locked: !model.is_editable,
            }),
        ],
        withFragment: (model) => [
            new LineElement({
                id: `fragment_${model.id}__line`,
                x1: model.a.x,
                y1: model.a.y,
                x2: model.b.x,
                y2: model.b.y,
                color: getDefaultColor(model.style, !model.is_editable),
                overrideRenderOrder: z.fragments.default,
                locked: !model.is_editable,
            }),
        ],
        withFragmentDashed: (model) => [
            new LineElement({
                id: `fragment-dashed_${model.id}__line`,
                x1: model.a.x,
                y1: model.a.y,
                x2: model.b.x,
                y2: model.b.y,
                color: getDefaultColor(model.style, !model.is_editable),
                overrideRenderOrder: z.fragments.default,
                isDashed: true,
                locked: !model.is_editable,
            }),
        ],
        withVector: (model) => [
            new VectorElement({
                id: `vector_${model.id}__line`,
                x1: model.from.x,
                y1: model.from.y,
                x2: model.to.x,
                y2: model.to.y,
                color: getDefaultColor(model.style, !model.is_editable),
                overrideRenderOrder: z.fragments.default,
                locked: !model.is_editable,
            }),
            new DotElement({
                x: model.to.x,
                y: model.to.y,
                id: `vector_${model.id}__to`,
                overrideRenderOrder: z.points.default,
                color: transparentColor,
                locked: !model.is_editable,
            }),
        ],
        withVectorDashed: (model) => [
            new VectorElement({
                id: `vector-dashed_${model.id}__line`,
                x1: model.from.x,
                y1: model.from.y,
                x2: model.to.x,
                y2: model.to.y,
                color: getDefaultColor(model.style, !model.is_editable),
                overrideRenderOrder: z.fragments.default,
                isDashed: true,
                locked: !model.is_editable,
            }),
            new DotElement({
                x: model.to.x,
                y: model.to.y,
                id: `vector_${model.id}__to`,
                overrideRenderOrder: z.points.default,
                color: transparentColor,
                locked: !model.is_editable,
            }),
        ],
        withParallelFragment: (fragment) => [
            new LineElement({
                id: `parallelFragment_${fragment.id}__line`,
                x1: fragment.a.x,
                y1: fragment.a.y,
                x2: fragment.b.x,
                y2: fragment.b.y,
                color: defaultColor,
                overrideRenderOrder: z.fragments.default,
                locked: !fragment.is_editable,
            }),
            new DotElement({
                id: `parallelFragment_${fragment.id}__virtualPoint`,
                x: fragment.b.x,
                y: fragment.b.y,
                color: virtualColor,
                overrideRenderOrder: z.points.virtual,
                locked: !fragment.is_editable,
            }),
        ],
        withLabelPoint: (model) => [
            LabelDotElement.create({
                id: `labelPoint_${model.id}__label`,
                x: model.x,
                y: model.y,
                value: model.label,
                rotationAngle: 0,
                directionAngle: model.directionAngle,
                color: getDefaultColor(model.style, !model.is_editable),
                overrideRenderOrder: z.labels.default,
                locked: !model.is_editable,
            }),
        ],
        withLabelFragment: (label) => [
            new LabelFragmentElement({
                id: `labelFragment_${label.id}__label`,
                x1: label.a.x,
                y1: label.a.y,
                x2: label.b.x,
                y2: label.b.y,
                value: label.value,
                rotationAngle: label.rotationAngle,
                directionAngle: Math.PI / 2,
                color: getDefaultColor(label.style, !label.is_editable),
                overrideRenderOrder: z.labels.default,
                altOrigin: label.altOrigin,
                locked: !label.is_editable,
            }),
        ],
        withLabelAngle: (label) => {
            const elements: BaseElement[] = [
                new AngleElement({
                    id: `labelAngle_${label.id}__angle`,
                    angleStart: label.angleStart,
                    angleEnd: label.angleEnd,
                    isRight: label.isRight,
                    x: label.vertex.x,
                    y: label.vertex.y,
                    color: getDefaultColor(label.style, !label.is_editable),
                    overrideRenderOrder: z.pieces.virtual + 1,
                    locked: !label.is_editable,
                }),
                new LabelAngleElement({
                    id: `labelAngle_${label.id}__labelAngle`,
                    x: label.vertex.x,
                    y: label.vertex.y,
                    rotationAngle: 0,
                    directionAngle: label.angleMiddle + Math.PI,
                    value: label.value,
                    isEditable: false,
                    color: getDefaultColor(label.style, !label.is_editable),
                    overrideRenderOrder: z.labels.default,
                    locked: !label.is_editable,
                }),
            ];
            return elements;
        },
        withRightAngle: (angle) => [
            new AngleElement({
                id: `rightAngle_${angle.id}__angle`,
                angleStart: angle.angleStart,
                angleEnd: angle.angleEnd,
                isRight: true,
                x: angle.vertex.x,
                y: angle.vertex.y,
                color: getDefaultColor(angle.style, !angle.is_editable),
                overrideRenderOrder: z.pieces.virtual + 1,
                locked: !angle.is_editable,
            }),
        ],
        withLine: (line) => [
            new LineElement({
                id: `line_${line.id}__line`,
                x1: line.virtualFragment.a.x,
                y1: line.virtualFragment.a.y,
                x2: line.virtualFragment.b.x,
                y2: line.virtualFragment.b.y,
                color: getDefaultColor(line.style, !line.is_editable),
                overrideRenderOrder: z.lines.default,
                locked: !line.is_editable,
            }),
        ],
        withConstraintLine: (line) => {
            const elements: BaseElement[] = [
                new LineElement({
                    id: `constraintLine_${line.id}__line`,
                    x1: line.virtualFragment.a.x,
                    y1: line.virtualFragment.a.y,
                    x2: line.virtualFragment.b.x,
                    y2: line.virtualFragment.b.y,
                    color: defaultColor,
                    overrideRenderOrder: z.lines.default,
                    locked: !line.is_editable,
                }),
            ];
            const intersectionPoint = line.tryGetIntersectionPoint();

            if (intersectionPoint) {
                const startAngle = line.constraintAngle;
                const endAngle = line.lineAngle;

                elements.push(
                    new AngleElement({
                        id: `constraintLine_${line.id}__rightAngle`,
                        x: intersectionPoint.x,
                        y: intersectionPoint.y,
                        isRight: true,
                        angleStart: startAngle,
                        angleEnd: endAngle,
                        color: virtualColor,
                        overrideRenderOrder: z.pieces.virtual,
                        locked: !line.is_editable,
                    }),
                    new LineElement({
                        id: `constraintLine_${line.id}__rightAngleLine`,
                        x1: intersectionPoint.x,
                        y1: intersectionPoint.y,
                        x2: line.constraintA.x,
                        y2: line.constraintA.y,
                        color: virtualColor,
                        thin: true,
                        overrideRenderOrder: z.lines.virtual,
                        locked: !line.is_editable,
                    }),
                    new DotElement({
                        id: `constraintLine_${line.id}__intersectionPoint`,
                        x: intersectionPoint.x,
                        y: intersectionPoint.y,
                        color: virtualColor,
                        overrideRenderOrder: z.points.virtual,
                        locked: !line.is_editable,
                    })
                );
            }

            return elements;
        },
        withComputedIntersectionPoints: (model) =>
            model.points.map(
                (point) =>
                    new DotElement({
                        id: `computedIntersectionPoints_${model.id}_${getPointHash(point)}`,
                        x: point.x,
                        y: point.y,
                        color: virtualColor,
                        overrideRenderOrder: z.points.virtual,
                        locked: !model.is_editable,
                    })
            ),
        withBisection: (bisection) => {
            if (!bisection.midPoint) return [];
            return [
                new LineElement({
                    id: `bisection_${bisection.id}__line`,
                    x1: bisection.angle.vertex.x,
                    y1: bisection.angle.vertex.y,
                    x2: bisection.midPoint.x,
                    y2: bisection.midPoint.y,
                    color: getVirtualColor(bisection.style, !bisection.is_editable),
                    overrideRenderOrder: z.fragments.virtual,
                    locked: !bisection.is_editable,
                }),
                new DotElement({
                    id: `bisection_${bisection.id}__dot`,
                    x: bisection.midPoint.x,
                    y: bisection.midPoint.y,
                    color: virtualColor,
                    overrideRenderOrder: z.points.virtual,
                    locked: !bisection.is_editable,
                }),
                new AngleElement({
                    id: `bisection_${bisection.id}__angle1`,
                    x: bisection.angle.vertex.x,
                    y: bisection.angle.vertex.y,
                    angleStart: bisection.angles.angleFirst,
                    angleEnd: bisection.angles.angleMiddle,
                    isRight: false,
                    color: getVirtualColor(bisection.style),
                    overrideRenderOrder: z.pieces.virtual,
                    segments: bisection.segmentsCount,
                    locked: !bisection.is_editable,
                }),
                new AngleElement({
                    id: `bisection_${bisection.id}__angle2`,
                    x: bisection.angle.vertex.x,
                    y: bisection.angle.vertex.y,
                    angleStart: bisection.angles.angleMiddle,
                    angleEnd: bisection.angles.angleLast,
                    isRight: false,
                    color: getVirtualColor(bisection.style),
                    offset: 1,
                    overrideRenderOrder: z.pieces.virtual,
                    segments: bisection.segmentsCount,
                    locked: !bisection.is_editable,
                }),
            ];
        },
        withMedian: (median) => [
            new LineElement({
                id: `median_${median.id}__line`,
                x1: median.vertex.x,
                y1: median.vertex.y,
                x2: median.midPoint.x,
                y2: median.midPoint.y,
                color: getVirtualColor(median.style),
                overrideRenderOrder: z.fragments.virtual,
                locked: !median.is_editable,
            }),
            new DotElement({
                id: `median_${median.id}__point`,
                x: median.midPoint.x,
                y: median.midPoint.y,
                color: virtualColor,
                overrideRenderOrder: z.points.virtual,
                locked: !median.is_editable,
            }),
            new StrokeElement({
                id: `median_${median.id}__strokeA`,
                a: median.fragment.a,
                b: median.midPoint,
                segments: median.segmentsCount,
                color: getVirtualColor(median.style),
                overrideRenderOrder: z.pieces.virtual,
            }),
            new StrokeElement({
                id: `median_${median.id}__strokeB`,
                a: median.fragment.b,
                b: median.midPoint,
                segments: median.segmentsCount,
                color: getVirtualColor(median.style),
                overrideRenderOrder: z.pieces.virtual,
            }),
        ],
        withAltitude: (altitude) => {
            const { startAngle, endAngle } = getAltitudeAngles({
                vertex: altitude.vertex,
                basis: altitude.basis,
                altitudePoint: altitude.projectionPoint,
            });
            const end = altitude.basis.offset || altitude.basis.fragment.a;

            return [
                new LineElement({
                    id: `altitude_${altitude.id}__line`,
                    x1: altitude.vertex.x,
                    y1: altitude.vertex.y,
                    x2: altitude.projectionPoint.x,
                    y2: altitude.projectionPoint.y,
                    color: getVirtualColor(altitude.style),
                    overrideRenderOrder: z.fragments.virtual,
                    locked: !altitude.is_editable,
                }),
                new DotElement({
                    id: `altitude_${altitude.id}__point`,
                    x: altitude.projectionPoint.x,
                    y: altitude.projectionPoint.y,
                    color: virtualColor,
                    overrideRenderOrder: z.points.virtual,
                    locked: !altitude.is_editable,
                }),
                new AngleElement({
                    id: `altitude_${altitude.id}__rightAngle`,
                    x: altitude.projectionPoint.x,
                    y: altitude.projectionPoint.y,
                    isRight: true,
                    angleStart: startAngle,
                    angleEnd: endAngle,
                    color: getVirtualColor(altitude.style),
                    overrideRenderOrder: z.pieces.virtual,
                    locked: !altitude.is_editable,
                }),
                new LineElement({
                    id: `altitude_${altitude.id}__util`,
                    x1: end.x,
                    y1: end.y,
                    x2: altitude.projectionPoint.x,
                    y2: altitude.projectionPoint.y,
                    overrideRenderOrder: z.fragments.virtual,
                    color: defaultColor.withOpacity(0.3),
                    thin: true,
                    locked: !altitude.is_editable,
                }),
            ];
        },
        withEqualSegments: (equalSegments) =>
            equalSegments.segments.map(
                ({ a, b }, i) =>
                    new StrokeElement({
                        id: `equalSegments_${equalSegments.id}__${i}`,
                        a,
                        b,
                        segments: equalSegments.segmentsCount,
                        color: getDefaultColor(equalSegments.style),
                        overrideRenderOrder: z.pieces.default,
                    })
            ),
        withEqualAngles: (equalAngles) =>
            equalAngles.angles.reduce<BaseElement[]>((all, angle, i) => {
                const { startAngle: angleStart, endAngle: angleEnd } = getAngles(angle);

                all.push(
                    new AngleElement({
                        id: `equalAngles_${equalAngles.id}__${i}`,
                        x: angle.vertex.x,
                        y: angle.vertex.y,
                        isRight: false,
                        segments: equalAngles.segmentsCount,
                        angleStart,
                        angleEnd,
                        color: getDefaultColor(equalAngles.style),
                        offset: i,
                        overrideRenderOrder: z.pieces.default - 1,
                        locked: !equalAngles.is_editable,
                    })
                );
                return all;
            }, []),
        withEllipse: (ellipse) => [
            new EllipseElement({
                id: `ellipse_${ellipse.id}__ellipse`,
                center: ellipse.center,
                rx: ellipse.rx,
                ry: ellipse.ry,
                color: getDefaultColor(ellipse.style),
                overrideRenderOrder: z.circles.default,
            }),
        ],
        withInscribedCircle: (circle) => {
            if (!circle.center || !circle.radius) return [];

            return [
                new EllipseElement({
                    id: `inscribedCircle_${circle.id}__ellipse`,
                    center: circle.center,
                    rx: circle.radius,
                    ry: circle.radius,
                    color: getDefaultColor(circle.style),
                    overrideRenderOrder: z.circles.default,
                }),
                new DotElement({
                    id: `inscribedCircle_${circle.id}__center`,
                    x: circle.center.x,
                    y: circle.center.y,
                    color: virtualColor,
                    overrideRenderOrder: z.points.virtual,
                    locked: !circle.is_editable,
                }),
                ...circle.intersectionPoints.map(
                    (point, i) =>
                        new DotElement({
                            id: `inscribedCircle_${circle.id}__intersectionPoint_${i}`,
                            x: point.x,
                            y: point.y,
                            color: virtualColor,
                            overrideRenderOrder: z.points.virtual,
                        })
                ),
            ];
        },
        withCircumscribedCircle: (circle) => {
            if (!circle.center || !circle.radius) return [];
            return [
                new EllipseElement({
                    id: `circumscribedCircle_${circle.id}__ellipse`,
                    center: circle.center,
                    rx: circle.radius,
                    ry: circle.radius,
                    color: getDefaultColor(circle.style),
                    overrideRenderOrder: z.circles.default,
                }),
                new DotElement({
                    id: `circumscribedCircle_${circle.id}__center`,
                    x: circle.center.x,
                    y: circle.center.y,
                    color: virtualColor,
                    overrideRenderOrder: z.points.virtual,
                    locked: !circle.is_editable,
                }),
            ];
        },
        withTangentLine: (tangent) => {
            if (!tangent.checkIsTangent()) return [];

            return [
                new DotElement({
                    id: `tangentLine_${tangent.id}__tangentPoint`,
                    x: tangent.pointOnCircle.x,
                    y: tangent.pointOnCircle.y,
                    color: virtualColor,
                    overrideRenderOrder: z.points.virtual,
                    locked: !tangent.is_editable,
                }),
                new LineElement({
                    id: `tangentLine_${tangent.id}__line`,
                    x1: tangent.virtualTangentLine.a.x,
                    y1: tangent.virtualTangentLine.a.y,
                    x2: tangent.virtualTangentLine.b.x,
                    y2: tangent.virtualTangentLine.b.y,
                    color: defaultColor,
                    overrideRenderOrder: z.fragments.default,
                    locked: !tangent.is_editable,
                }),
            ];
        },
        withMiddleLine: ({ id, middleLineFragment, is_editable }) => {
            const { a, b } = middleLineFragment;
            return [
                new DotElement({
                    id: `middleLine_${id}__a`,
                    x: a.x,
                    y: a.y,
                    color: virtualColor,
                    overrideRenderOrder: z.points.virtual,
                    locked: !is_editable,
                }),
                new DotElement({
                    id: `middleLine_${id}__b`,
                    x: b.x,
                    y: b.y,
                    color: virtualColor,
                    overrideRenderOrder: z.points.virtual,
                    locked: !is_editable,
                }),
                new LineElement({
                    id: `middleLine_${id}__line`,
                    x1: a.x,
                    y1: a.y,
                    x2: b.x,
                    y2: b.y,
                    color: virtualColor,
                    overrideRenderOrder: z.fragments.virtual,
                    locked: !is_editable,
                }),
            ];
        },
        withTangentLineOnCircle: (tangent) => {
            if (!tangent.hasTangent()) return [];
            const { id, touchPoint, virtualTangentFragment, is_editable } = tangent;

            const {
                a: { x: x1, y: y1 },
                b: { x: x2, y: y2 },
            } = virtualTangentFragment;

            return [
                new LineElement({
                    id: `tangentOnCircle_${id}_line`,
                    x1,
                    y1,
                    x2,
                    y2,
                    overrideRenderOrder: z.lines.default,
                    color: defaultColor,
                    locked: !is_editable,
                }),
                new DotElement({
                    id: `tangentOnCircle_${id}_touchPoint`,
                    x: touchPoint.x,
                    y: touchPoint.y,
                    overrideRenderOrder: z.points.virtual,
                    color: virtualColor,
                    locked: !is_editable,
                }),
            ];
        },
    };
    return (model: BaseModel) => model.accept<BaseElement[]>(mapModelToElementsVisitor);
};

export const nullColorTransformer: TTransformColor = (color) => color;
export const opacifyColor: TTransformColor = (color) => color.withOpacity(0.4);

export function removeModel(figure: Figure2D, model: BaseModel) {
    const i = figure.models.indexOf(model);
    assert(i !== -1);
    figure.models.splice(i, 1);
}

const sn = (n: number) => n.toFixed(4);
const sp = (p: TPoint) => `${sn(p.x)}_${sn(p.y)}`;

const elementPositionHashVisitor: IElementVisitor<string | string[]> = {
    withDot: (dot) => `dot__${sp(dot)}`,
    withAngle: (angle) =>
        [
            { start: angle.angleStart, end: angle.angleEnd },
            { start: angle.angleEnd, end: angle.angleStart },
        ].map(({ start, end }) => `angle__${sp(angle)}__${sn(start)}__${sn(end)}`),
    withLabelAngle: (label) => `labelAngle__${sp(label)}__${sn(label.directionAngle)}`,
    withLabelDot: (label) => `labelDot__${sp(label)}__${sn(label.directionAngle)}`,
    withLabelFragment: (label) => `labelFragment__${sp(label)}__${sn(label.rotationAngle)}`,
    withLine: (line) =>
        [
            { a: createPoint(line.x1, line.y1), b: createPoint(line.x2, line.y2) },
            { a: createPoint(line.x2, line.y2), b: createPoint(line.x1, line.y1) },
        ].map(({ a, b }) => `line__${sp(a)}_${sp(b)}`),
    withStroke: (stroke) =>
        [
            { a: stroke.a, b: stroke.b },
            { a: stroke.b, b: stroke.a },
        ].map(({ a, b }) => `stroke__${sp(a)}_${sp(b)}`),
    withEllipse: (ellipse) => `ellipse__${sp(ellipse.center)}_${sn(ellipse.rx)}_${sn(ellipse.ry)}`,
    withVector: ({ x1, y1, x2, y2 }) => `vector__${sp(createPoint(x1, y1))}_${sp(createPoint(x2, y2))}`,
};

export function getElementPositionHash(element: BaseElement): string[] {
    const hashes = element.accept(elementPositionHashVisitor);
    if (Array.isArray(hashes)) return hashes;
    return [hashes];
}
