import { computed, makeObservable, observable } from "mobx";

import { getBisectionLine } from "@viuch/geometry-lib/bisections";
import { areSamePoints, findPerpendicularPoint } from "@viuch/geometry-lib/check-geometry";
import { copyPoint, createFragment, createLine } from "@viuch/geometry-lib/factories";
import { intersectionLines } from "@viuch/geometry-lib/intersection";
import { subtractVectors, vectorLength } from "@viuch/geometry-lib/vectors";
import { generateId } from "@viuch/shared/utils/data";
import { assert } from "@viuch/utils/debug";

import type { IModelVisitor } from "../BaseModel";
import type { TModelStyle } from "../modelStyle";
import type { TAngle, TEllipse, TFragment, TLine, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

export interface IInscribedCircleModel {
    fragmentsPoints: TPoint[];
    style: TModelStyle | null;
    is_editable?: boolean;
}

export class InscribedCircleModel extends BaseModel implements IInscribedCircleModel {
    fragmentsPoints: TPoint[];
    style: TModelStyle | null;

    constructor(data: IInscribedCircleModel, id: number) {
        assert(data.fragmentsPoints.length >= 3);

        super(id);
        this.fragmentsPoints = data.fragmentsPoints.map(copyPoint);
        this.style = data.style;
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            fragmentsPoints: observable,
            lines: computed,
            fragments: computed,
            angles: computed,
            center: computed,
            radius: computed,
            intersectionPoints: computed,
            style: observable,
        });
    }

    static create(data: IInscribedCircleModel) {
        return new InscribedCircleModel(data, generateId());
    }

    get lines(): TLine[] {
        const lines: TLine[] = [];
        for (let i = 0; i < this.fragmentsPoints.length - 1; i++) {
            const a = this.fragmentsPoints[i];
            const b = this.fragmentsPoints[i + 1];

            lines.push(createLine(a, b));
        }
        lines.push(createLine(this.fragmentsPoints.at(-1)!, this.fragmentsPoints[0]));
        return lines;
    }

    get fragments(): TFragment[] {
        const fragments: TFragment[] = [];
        for (let i = 0; i < this.fragmentsPoints.length - 1; i++) {
            const a = this.fragmentsPoints[i];
            const b = this.fragmentsPoints[i + 1];

            fragments.push({ a, b });
        }
        fragments.push(createFragment(this.fragmentsPoints.at(-1)!, this.fragmentsPoints[0]));
        return fragments;
    }

    get angles(): TAngle[] {
        const length = this.fragmentsPoints.length;
        const angles: TAngle[] = [];

        angles.push({
            start: this.fragmentsPoints[length - 1],
            vertex: this.fragmentsPoints[0],
            end: this.fragmentsPoints[1],
        });
        for (let i = 1; i < this.fragmentsPoints.length - 1; i++) {
            const start = this.fragmentsPoints[i - 1];
            const vertex = this.fragmentsPoints[i];
            const end = this.fragmentsPoints[i + 1];
            angles.push({ vertex, start, end });
        }
        angles.push({
            start: this.fragmentsPoints[length - 2],
            vertex: this.fragmentsPoints[length - 1],
            end: this.fragmentsPoints[0],
        });

        return angles;
    }

    get center(): TPoint | null {
        return this.getCenterPoint();
    }

    get radius(): number | null {
        return this.center ? this.getRadius(this.center) : null;
    }

    private getCenterPoint(): TPoint | null {
        const bisectLines = this.angles.map(getBisectionLine);
        const point = intersectionLines(bisectLines[0], bisectLines[bisectLines.length - 1]);
        if (!point) return null;

        for (let i = 1; i < bisectLines.length; i++) {
            const p = intersectionLines(bisectLines[i], bisectLines[i - 1]);
            if (!p || !areSamePoints(point, p)) {
                return null;
            }
        }

        return point;
    }

    get intersectionPoints(): TPoint[] {
        if (!this.center) return [];
        return this.getIntersectionPoints(this.lines, this.center);
    }

    private getIntersectionPoints(fragments: TLine[], center: TPoint): TPoint[] {
        return fragments.map<TPoint>((line) => findPerpendicularPoint(line, center));
    }

    private getRadius(centerPoint: TPoint): number {
        const [a, b] = this.fragmentsPoints;
        const line = createLine(a, b);

        const projection = findPerpendicularPoint(line, centerPoint);
        return vectorLength(subtractVectors(projection, centerPoint));
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withInscribedCircle(this);
    }

    get rx() {
        return this.radius;
    }

    get ry() {
        return this.radius;
    }

    isEllipse(): this is TEllipse & { radius: number } {
        return !!this.center;
    }
}
