import type { Answer } from "../../../entities/tasks/Answer";
import type { Variable } from "../../../entities/tasks/Variable";
import type { BaseVariableContent } from "../../../entities/tasks/variables";
import type { TTaskVariableRequest, TTaskVariableResponse, TTaskAnswerRequest } from "../types";

import { serializePrimaryHints } from "../../../entities/tasks/utils";

import { variableContentSerializer } from "./variableContentSerializer";

export function serializeVariable(variable: Variable): TTaskVariableRequest {
    return {
        content: serializeVariableContent(variable.content),
        generation_type: variable.generation,
        name: variable.name,
    };
}

export function serializeVariableContent(content: BaseVariableContent): TTaskVariableResponse["content"] {
    return content.accept(variableContentSerializer);
}

export function serializeAnswer(answer: Answer, index: number): TTaskAnswerRequest {
    return {
        content: serializeVariableContent(answer.content),
        order: index,
        primary_hints: answer.primaryHints ? serializePrimaryHints(answer.primaryHints) : null,
        description: answer.description,
        description_formula: answer.descriptionFormula,
        generation_type: answer.generation,
        name: answer.name,
    };
}
