import { agent } from "../../agent";

export class ThemeKnowledgeLinksService {
    async themeKnowledgeLinks(): Promise<TRawThemeKnowledgeLink[]> {
        const { data } = await agent.get<TRawThemeKnowledgeLink[]>(`/theme_knowledge_links/`);

        return data;
    }

    async updateKnowledgeLink(request: TRawThemeKnowledgeLinkUpdate): Promise<TRawThemeKnowledgeLink> {
        const { data } = await agent.patch<TRawThemeKnowledgeLink>(`/theme_knowledge_links/${request.id}/`, request);

        return data;
    }
}

export type TRawThemeKnowledgeLink = {
    id: number;
    tag: string;
    tag_name: string;
    theme: TRawThemeKnowledgeLink_Theme | null;
    allow_as_source: boolean;
};

export type TRawThemeKnowledgeLink_Theme = {
    id: number;
    name: string;
    slug: string;
};

export type TRawThemeKnowledgeLinkUpdate = {
    id: number;
    theme: number | null;
    allow_as_source: boolean;
};
