import { computed, makeObservable, observable } from "mobx";

import { copyEllipse, copyPoint } from "@viuch/geometry-lib/factories";
import { getLineFromFragment } from "@viuch/geometry-lib/solvers";
import { generateId } from "@viuch/shared/utils/data";

import type { IModelVisitor } from "../BaseModel";
import type { TEllipse, TFragment, TPoint } from "@viuch/geometry-lib/types";

import { isPointOnEllipse } from "../../services/actions/utils";
import { BaseModel } from "../BaseModel";

import { getTangentLineFromPointOnEllipse } from "./tangent-utils";

export interface ITangentLineOnCircleModel {
    ellipse: TEllipse;
    touchPoint: TPoint;
    is_editable?: boolean;
}

export class TangentLineOnCircleModel extends BaseModel implements ITangentLineOnCircleModel {
    ellipse: TEllipse;
    touchPoint: TPoint;

    constructor(data: ITangentLineOnCircleModel, id: number) {
        super(id);

        this.ellipse = copyEllipse(data.ellipse);
        this.touchPoint = copyPoint(data.touchPoint);
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            ellipse: observable,
            touchPoint: observable,
            virtualTangentFragment: computed,
        });
    }

    hasTangent(): this is NonNullableObject<Pick<this, "virtualTangentFragment">> {
        return isPointOnEllipse(this.ellipse, this.touchPoint);
    }

    get virtualTangentFragment(): TFragment | null {
        if (!this.hasTangent()) return null;

        const { a, b } = getTangentLineFromPointOnEllipse(this.ellipse, this.touchPoint);
        return getLineFromFragment(a, b);
    }

    accept<R>(visitor: IModelVisitor<R>): R {
        return visitor.withTangentLineOnCircle(this);
    }

    static create(data: ITangentLineOnCircleModel) {
        return new TangentLineOnCircleModel(data, generateId());
    }
}
