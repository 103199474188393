import { computed, makeObservable, observable } from "mobx";

import { getLineAngle, normalizeAngle } from "@viuch/geometry-lib/angles";
import { findPerpendicularPoint } from "@viuch/geometry-lib/check-geometry";
import { copyBasis, copyFragment, copyPoint, createFragment, createLine } from "@viuch/geometry-lib/factories";
import { generateId } from "@viuch/shared/utils/data";

import type { TModelStyle } from "../modelStyle";
import type { TBasis, TFragment, TPoint } from "@viuch/geometry-lib/types";

import { BaseModel } from "../BaseModel";

import { getRealBasis } from "./utils";

export interface IAltitudeModel {
    vertex: TPoint;
    basis: TBasis;
    style: TModelStyle | null;
    is_editable?: boolean;
}

export class AltitudeModel extends BaseModel implements IAltitudeModel {
    vertex: TPoint;
    basis: TBasis;
    style: TModelStyle | null;

    constructor(data: IAltitudeModel, id: number) {
        super(id);

        this.basis = copyBasis(data.basis);
        this.vertex = copyPoint(data.vertex);
        this.style = data.style;
        this.is_editable = data.is_editable ?? true;

        makeObservable(this, {
            vertex: observable,
            basis: observable,
            projectionPoint: computed,
            altitudeFragment: computed,
            angles: computed,
            style: observable,
        });
    }

    get realBasisLine(): TFragment {
        return getRealBasis(this.basis);
    }

    get projectionPoint(): TPoint {
        const { a, b } = this.realBasisLine;
        return findPerpendicularPoint(createLine(a, b), this.vertex);
    }

    get altitudeFragment(): TFragment {
        return copyFragment({ a: this.vertex, b: this.projectionPoint });
    }

    accept<R>(visitor: IAltitudeModelVisitor<R>): R {
        return visitor.withAltitude(this);
    }

    static create(data: IAltitudeModel) {
        return new AltitudeModel(data, generateId());
    }

    get angles() {
        const altitudeLine = createLine(this.vertex, this.projectionPoint);
        const angleAltitude = getLineAngle(altitudeLine, true);
        const angleBase = normalizeAngle(angleAltitude - Math.PI / 2);

        return { angleAltitude, angleBase };
    }

    toAltitudeFragment() {
        return createFragment(this.vertex, this.projectionPoint);
    }
}

export interface IAltitudeModelVisitor<R> {
    withAltitude: (altitude: AltitudeModel) => R;
}
